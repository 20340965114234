@charset "utf-8";
/*============================================

  OTHER PAGE

==============================================*/
/*--------------------------------------------
  COMPANY
--------------------------------------------*/
.p-company_information{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
}
.p-company_access{
  padding: $margin_ll 0;
  background: #fafafa;
  @include mq(sm){
    padding: $margin_md 0;
  }
}
/*--------------------------------------------
  COMPLIANT
--------------------------------------------*/
.p-complaint_main{
  padding: $margin_lg 0;
  &__content{
    line-height: 2.25;
    &:nth-child(n+2){
      margin-top: $margin_md;
      padding-top: $margin_md;
      border-top: solid 1px #f1f1f1;
    }
    .c-multicol__title{
      margin-bottom: 0;
    }
    h3{
      font-size: 1.5em;
      margin-bottom: 1em;
      &:not(:first-child){
        margin-top: 2em;
      }
    }
    .c-dot_list{
      li{
        line-height: 2.25;
        &:before{
          top: 1.1125em;
        }
        &:nth-child(n+2){
          margin-top: 0;
        }
      }
    }
    figure{
      img{
        max-width: 100%;
        height: auto;
      }
    }
    ol{
      line-height: 2.25;
      counter-reset:list_count01 0;
      > li{
        position: relative;
        padding-left: 1.8em;
        counter-increment: list_count01 1;
        &:before{
          position: absolute;
          content:counter(list_count01)')';
          top: 0;
          left: 0;
          width: 1.5em;
        }
        &:nth-child(n+2){
          margin-top: 3em;
        }
      }
    }
  }
  @include mq(sm-md){
    &__content{
      .c-multicol__title{
        width: 30%;
      }
      .c-multicol__content{
        width: 70%;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__content{
      &:nth-child(n+2){
        margin-top: $margin_ss;
        padding-top: $margin_ss;
      }
      .c-multicol__title{
        margin-bottom: 1em;
      }
      h3{
        font-size: 1.25em;
        margin-bottom: .75em;
      }
      p{
        line-height: 1.6;
        &:not(:last-child){
          margin-bottom: .5em;
        }
      }
      .c-dot_list{
        li{
          line-height: 1.6;
          &:before{
            top: .8em;
          }
          &:nth-child(n+2){
            margin-top: .5em;
          }
        }
      }
      ol{
        li{
          line-height: 1.6;
          &:nth-child(n+2){
            margin-top: 2em;
          }
        }
      }
    }
  }
  @include mq(xs){
  }
}
/*--------------------------------------------
  APPLICABLE
--------------------------------------------*/
.p-standards_main{
  padding: $margin_lg 0;
  &__content{
    justify-content: space-between;
    &:nth-child(n+2){
      margin-top: $margin_ss;
      padding-top: $margin_ss;
      border-top:solid 1px #eee;
    }
    .image{
      width: 35%;
      img{
        max-width:100%;
        height: auto;
      }
    }
    .text{
      width: 60%;
      .c-ttl03{
        .ttl_ja{
          margin-bottom: 1em;
        }
        margin-bottom: .5em;
      }
      ul{
        padding-left: 1em;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__content{
      .text{
        ul{
          padding-left: 0;
        }
      }
    }
  }
  @include mq(ss){
    &__content{
      display: block;
      .image{
        width: 100%;
        margin-bottom: 2rem;
      }
      .text{
        width: 100%;
      }
    }
  }
}
/*--------------------------------------------
  DECLARATION
--------------------------------------------*/
.p-declaration_main{
  padding: $margin_lg 0;
  &__text{
    text-align: center;
    font-size: 1.8rem;
  }
  &__list{
    padding: 5px;
    margin: $margin_ss 0;
    background: -webkit-linear-gradient(45deg, $accent_color 0%, $sub_color 50%, $main_color 100%);
    background: -o-linear-gradient(45deg, $accent_color 0%,  $sub_color 50%, $main_color 100%);
    background: linear-gradient(-45deg, $accent_color 0%,  $sub_color 50%, $main_color 100%);
    ul{
      background: #fff;
      padding: $margin_sm;
      li{
        align-items: flex-end;
        flex-wrap: nowrap;
        &:nth-child(n+2){
          border-top:solid 1px #eee;
          padding-top: $margin_sm/2;
          margin-top: $margin_sm/2;
        }
        .c-ttl03{
          width: 30%;
          padding-right: 1em;
          margin-bottom: 0;
          .ttl_ja{
            margin-bottom: .5em;
          }
        }
        p{
          font-size: 1.8rem;
          width: 75%;
        }
      }
    }
  }
  @include mq(w1400){
    &__text{
      font-size: 1.6rem;
    }
    &__list{
      ul{
        padding: $margin_ss;
        li{
          .c-ttl03{
            font-size: 4.2rem;
          }
          p{
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
      ul{
        padding: $margin_sm/2;
        li{
          display: block;
          .c-ttl03{
            width: 100%;
            padding: 0;
            margin-bottom: .25em;
          }
          p{
            width: 100%;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__text{
      line-height: 2;
    }
    &__list{
      padding: 3px;
      ul{
        padding: $margin_ss 4%;
        li{
          .c-ttl03{
            margin-bottom: .25em;
          }
          p{
            width: 100%;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__list{
      margin: 3rem 0;
    }
  }
}
/*--------------------------------------------
  PRIVACY POLICY
--------------------------------------------*/
.p-privacy_policy{
  padding: $margin_lg 0;
  &__text{
    line-height: 2;
    margin-bottom: $margin_sm;
  }
  &__list{
    dt{
      position: relative;
      font-size: 2rem;
      padding-left: 1.5em;
      margin-bottom: .75em;
      &:before{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: 1em;
        height: 1em;
        border-radius:50%;
        background: $main_color;
        transform:scale(.375);
      }
    }
    dd{
      line-height: 2;
      &:not(:last-child){
        margin-bottom:$margin_sm;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__text{
      margin-bottom:$margin_ss;
    }
    &__list{
      dd{
        &:not(:last-child){
          margin-bottom:$margin_ss;
        }
      }
    }
  }
  @include mq(xs){
    &__list{
      dt{
        font-size: 1.8rem;
      }
    }
  }
}
/*--------------------------------------------
  NEWS
--------------------------------------------*/
.p-news_archive{
  padding: $margin_lg 0;
  &__list{
    margin-bottom: 5rem;
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
      margin-bottom: $margin_ss;
    }
  }
}

.p-news_single{
  padding: $margin_lg 0;
  &__head{
    position: relative;
    font-size: 3.2rem;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: solid 1px $font_color;
    time{
      display: block;
      font-size: .5em;
      color:#bababa;
      font-family: $base_font_en;
      line-height: 1;
      margin-bottom: 1em;
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__head{
      font-size: 2.4rem;
    }
  }

}
/*--------------------------------------------
  CONTACT
--------------------------------------------*/
.p-contact_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_sm 0;
  }
}

/*--------------------------------------------
  NOT FOUND
--------------------------------------------*/
.p-notfound_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_sm 0;
  }
  h2{
    font-size:  1.5em;
    margin-bottom: 1em;
    text-align: center;
  }
  p{
    text-align: center;
    line-height:  2.5;
    margin-bottom:  2em;
  }
  @include mq(xs){
    h2{
      text-align: left;
    }
    p{
      text-align: left;  
    }
  }
}
