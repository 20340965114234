@charset "utf-8";
/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
.l-main{
  @include mq(w1400-){
    padding-top:6.25em;
  }
  // @include mq(sm-){
  //   padding-top:6.25em;
  // }
}
.l-breadcrumb{
  padding: 1em 0;
  line-height: 1;
  &__list{
    justify-content: flex-start;
    align-items: center;
    li{
      position: relative;
      padding-right:2em;
      &:after{
        position: absolute;
        content:"";
        right: .5em;
        top:50%;
        width: 1em;
        height: 1px;
        background: #b7b8b9;
      }
      a{
        position: relative;
        padding:5px 0;
        display: inline-block;
        &:after{
          position: absolute;
          content:"";
          left: 0;
          bottom:0;
          width: 100%;
          height: 1px;
          background: $font_color;
          transition:$transition_val;
        }
        &:hover{
          color:#b7b8b9;
          &:after{
            background: #b7b8b9;
            transform:scaleX(0);
          }
        }
      }
      &:last-child{
        padding-right:0;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
          color:#b7b8b9;
          &:after{
            display: none;
          }
        }
      }
    }
  }
}