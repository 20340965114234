@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer_top{
  background: #202020;
  padding: $margin_lg 0;
  &__inner{
    justify-content: space-between;
  }
  &__info{
    .logo{
      margin-bottom: 3rem;
    }
    .address{
      color:#fff;
      font-size: 1.4rem;
      line-height: 1.71;
    }
  }
  &__menu{
    line-height: 1;
    dl{
      &:nth-child(n+2){
        margin-left:$margin_lg;
      }
      dt{
        color: #fff;
        &:not(:first-child){
          margin-top:calc(1.875em - 5px);
        }
      }
      dd{
        margin-top: 1.5em;
        font-size: 1.4rem;
        li{
          position: relative;
          padding-left: 1em;
          &:nth-child(n+2){
            margin-top: calc(1.5em - 6px);
          }
          color:#fff;
          &:before{
            position: absolute;
            content:"";
            width: .5em;
            height: 1px;
            left: 0;
            bottom:50%;
            background: #fff;
          }
        }
      }
      a{
        position: relative;
        display: inline-block;
        color:#fff;
        padding: 5px 0;
        &:before{
          position: absolute;
          content:"";
          left: 0;
          bottom:0;
          width: 0%;
          height: 1px;
          background: #fff;
          transition:$transition_val;
        }
        @include mq(sm-){
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
    }
  }
  @include mq(w1400){
    &__info{
      .logo{
        img{
          height: 10rem;
        }
      }
    }
  }
  @include mq(sm){
    padding: $margin_ss 0;
    &__inner{
      display: block;
    }
    &__info{
      display: flex;
      justify-content: center;
      align-items: center;
      .logo{
        margin-bottom: 0;
        margin-right:3rem;
        img{
          height: 8rem;
        }
      }
      .address{
        font-size: 1.6rem;
      }
    }
  }
}
.l-footer_bottom{
  padding: 3rem 0;
  background: #000;
  &__inner{
    justify-content: space-between;
    align-items: center;
  }
  &__menu{
    font-size: 1.4rem;
    line-height: 1;
    li{
      a{
        position: relative;
        color:#fff;
        padding: 3px 0;
        &:before{
          position: absolute;
          content:"";
          left: 0;
          bottom: 0;
          width: 0;
          height: 1px;
          background: #fff;
          transition:$transition_val;
        }
        @include mq(sm-){
          &:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__copy{
    line-height: 1;
    color:#fff;
    font-size: 1.4rem;
    font-family: $base_font_en;
  }
  @include mq(sm){
    &__inner{
      justify-content: center;
    }
    padding: 2rem 0;
  }
}