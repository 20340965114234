@charset "utf-8";
/*============================================

  APPLICATION

==============================================*/
.p-application_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
  &__cover{
    display: flex;
    justify-content: center;
  }
  &__link{
    &:nth-child(n+2){
      margin-left: 1em;
    }
  }
  @include mq(xs){
    &__link{
      line-height: 1.34;
    }
  }
}

.p-application_register{
  width: 80%;
  margin:  auto;
  &:nth-child(n+2){
    margin-top: 10rem;
  }
  &__tbl{
    tr{
      &:nth-child(n+2){
        border-top: solid 1px #ccc;
      }
    }
    th,td{
      padding: 1.25em 1em;
    }
    th{
      width: 25%;
      text-align: left;
    }
    td{
      width: 75%;
    }
  }
  &__link{
    color: $main_color;
    text-decoration: underline;
    &:hover{
      color: $accent_color;
    }
  }
  &__list{
    margin-top: 1em;
    li{
      text-indent: -1em;
      margin-left: 1em;
    }
  }
  @include mq(sm){
    width: 100%;
    &:nth-child(n+2){
      margin-top: 8rem;
    }
  }
  @include mq(ss){
    &__tbl{
      tr{
        display: block;
        padding: 1.5em 0;
      }
      th,
      td{
        display: block;
        width: 100%;
        padding: 0;
      }
      th{
        margin-bottom: 1em;
      }
    }
  }
}
