@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
  li{
    &:nth-child(n+2){
      margin-left:1.875em;
    }
    &:last-child{
      // margin-left:0;
    }
    a{
      position: relative;
      display: block;
      span{
        padding:5px 0;
        display: block;
      }
      &:after{
        position: absolute;
        content:"";
        left: 0;
        bottom:0;
        width: 100%;
        height: 1px;
        background: $font_color;
        transform:scaleX(0);
        transition:$transition_val;
      }

      &:hover{
        opacity: .75;
        &:after{
          transform:scaleX(1);
        }
      }
      &.contact_btn{
        position: relative;
        padding:2.625em 1.875em;
        background: -webkit-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
        background: -o-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
        background: linear-gradient(to right, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
        background-size:300% 100%;
        background-position: right center;
        color:#fff;
        font-family:$base_font_en;
        span{
          position: relative;
          display: block;
          padding: 0 0 0 1.625em;
          &:before{
            position: absolute;
            left: 0;
            top:50%;
            transform:translateY(-50%);
            @include icon('mail');
          }
        }
        &:after{
          display: none;
        }
        &:hover{
          opacity: 1;
          background-position: left center;
        }
        &.-blue{
          background: -webkit-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
          background: -o-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
          background: linear-gradient(to right, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
          background-size:300% 100%;
          background-position: right center;   
          &:hover{
            opacity: 1;
            background-position: left center;
          }
        }
      }
    }
  }
}
.l-so_menu{
  background: -webkit-linear-gradient(left, $accent_color 0%, $sub_color 50%, $main_color 100%);
  background: -o-linear-gradient(left, $accent_color 0%, $sub_color 50%, $main_color 100%);
  background: linear-gradient(to right, $accent_color 0%, $sub_color 50%, $main_color 100%);
  @include mq(sm-){
    padding-left: $body_margin;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  dl{
    line-height: 1;
    color:#fff;
    font-size: 2.8rem;
    &:nth-child(n+2){
      margin-left:8rem;
    }
    a{
      position: relative;
      color:#fff;
      padding:5px 0;
      display: inline-block;
      &:before{
        position: absolute;
        content:"";
        width: 0;
        height: 1px;
        bottom:0;
        left: 0;
        background: #fff;
        transition: $transition_val;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
          &:before{
            width: 100%;
          }
        }
      }
    }
    dt{
      font-size: 1em;
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
    dd{
      font-size: .85em;
      margin-top: 1em;
      li{
        position: relative;
        padding-left: 1em;
        &:nth-child(n+2){
          margin-top: .5em;
        }
        &:before{
          position: absolute;
          content:"";
          left: 0;
          bottom:50%;
          width: .5em;
          height: 1px;
          background: #fff;
        }
      }
    }
  }
  @include mq(md){
    dl{
      font-size: 2.4rem;
    }
  }
  @include mq(sm){
    padding: $margin_md;
    .inner{
      display: block;
    }
    dl{
      &:nth-child(n+2){
        margin-left: 0;
        margin-top: 1em;
      }
      dd{
        padding-left: 1em;
      }
    }
  }
  @include mq(xs){
    padding: $margin_sm;
  }
  @include mq(ss){
    background: -webkit-linear-gradient(-45deg, $accent_color 0%, $sub_color 50%, $main_color 100%);
    background: -o-linear-gradient(-45deg, $accent_color 0%, $sub_color 50%, $main_color 100%);
    background: linear-gradient(135deg, $accent_color 0%, $sub_color 50%, $main_color 100%);
    padding: $margin_md 4%;
    dl{
      font-size: 2rem;
    }
  }
}