@charset "utf-8";
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
@keyframes draw_path01 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 2;
    fill-opacity:0;
  }

  75% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:0;
  }

  80% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:0;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:1;
  }
}
@keyframes draw_path02 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 2;
    fill-opacity:0;
  }

  75% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:0;
  }

  80% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:0;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity:0;
  }
}

/*--------------------------------------------
  MIXIN
--------------------------------------------*/
@mixin animation($type: 'fadeup',$status: 'before'){
  @if $type == 'fadeUp' {
    @if $status == 'before' {
      transform:translateY($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateY(0);
      opacity: 1;
    }
  }
  @else if $type == 'fadeIn' {
    @if $status == 'before' {
      opacity: 0;
    }@else if $status == 'after' {
      opacity: 1;
    }
  }
}

