@charset "utf-8";
/*============================================

  PROCESS

==============================================*/
.p-process_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
}
.p-process_box{
  position: relative;
  background: #fafafa;
  padding: $margin_md;
  &:nth-child(n+2){
    margin-top: $margin_lg;
    &:before{
      position: absolute;
      content:"";
      left: 50%;
      bottom: calc(100% + 2rem);
      border-style: solid;
      border-width: $margin_lg  - 4rem $margin_sm 0;
      border-color: $font_color transparent;
      transform: translateX(-50%);
    }
  }
  @include mq(sm){
    padding: $margin_sm;
    &:nth-child(n+2){
      margin-top: $margin_md;
      &:before{
        border-width: $margin_md  - 4rem $margin_ss 0;
      }
    }
  }
  @include mq(xs){
    padding: $margin_ss 4%;
    &:nth-child(n+2){
      &:before{
        bottom: calc(100% + 2rem);
        border-width: $margin_sm  - 2rem $margin_ss 0;
      }
    }
  }
}
.p-process_list{
  .arrow{
    position: relative;
    width: 2rem;
    margin:0 auto;
    height: 5rem;
    i{
      position: absolute;
      left: 50%;
      top: 1rem;
      width: .4rem;
      height: calc(100% - 2rem);
      transform:translateX(-50%);
      background: $font_color;
      &:before,
      &:after{
        position: absolute;
        content:"";
        bottom:0;
        width: 1rem;
        height: .6rem;
        background: $font_color;
      }
      &:before{
        left: .2rem;
        transform-origin: left bottom;
        transform: skewY(-35deg) translateY(.2rem);
      }
      &:after{
        right: .2rem;
        transform-origin: right bottom;
        transform: skewY(35deg) translateY(.2rem);
      }
    }
  }
  &__item{
    .box{
      border:solid 1px #cdcdcd;
      line-height: 1.4;
      font-size: 2.4rem;
      padding: 1em;
      background: #fff;
      text-align: center;
      span{
        font-size: .6666666em;
        margin-left: .5em;
        &:before{
          content:"(";
        }
        &:after{
          content:")";
        }
      }
    }
    // &.-strong{
    //   .box{
    //     background: $font_color;
    //     color:#fff;
    //   }
    // }
  }
  &__row{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    >.arrow{
      position: absolute;
      margin:0;
      height: 100%;
      left: 50%;
      transform:translateX(-50%);
    }
    ul{
      width: 45%;
      &:first-child{
        margin-right: 10%;
      }
    }
  }
  @include mq(sm){
    &__item{
      .box{
        font-size: 2rem;
        span{
          display: block;
          margin-left: 0;
        }
      }
    }
  }
  @include mq(xs){
    &__item{
      .box{
        font-size: 1.8rem;
      }
    }
  }
  @include mq(ss){
    &__item{
      .box{
        padding: 1em .5em;
      }
    }
  }
}
.p-process_notes{
  margin-top: $margin_ss;
  padding: $margin_ss;
  background: #fef3f3;
  border:solid 1px #ecaaa9;
  &__list{
    li{
      position: relative;
      padding-left: 1em;
      &:before{
        position: absolute;
        content:"・";
        left: 0;
        top: 0;
      }
    }
  }
  &__text{
    dt{
      line-height: 1.875;
      &:before{
        content:"＜";
      }
      &:after{
        content:"＞";
      }
    }
    dd{
      &:not(:last-child){
        margin-bottom: 1em;
      }
    }
  }
  @include mq(sm){
    padding: 3rem;
    margin-top: 3rem;
  }
  @include mq(sm){
    padding: 3rem 2rem;
  }
}