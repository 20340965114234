@charset "UTF-8";
/*--------------------------------------------
  setting
--------------------------------------------*/
/*--------------------------------------------
  value
--------------------------------------------*/
/*--------------------------------------------
  MIXIN 等
--------------------------------------------*/
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
@keyframes draw_path01 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 2;
    fill-opacity: 0; }
  75% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 0; }
  80% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 0; }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 1; } }

@keyframes draw_path02 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 2;
    fill-opacity: 0; }
  75% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 0; }
  80% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 0; }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    fill-opacity: 0; } }

/*--------------------------------------------
  MIXIN
--------------------------------------------*/
/*--------------------------------------------
  BASE
--------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

img {
  vertical-align: top; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

ul li,
ol li {
  list-style-type: none; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1; }

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 1199px) {
    html {
      font-size: .8vw; } }
  @media screen and (max-width: 991px) {
    html {
      font-size: 1.3vw; } }
  @media screen and (max-width: 767px) {
    html {
      font-size: 1.75vw; } }
  @media screen and (max-width: 575px) {
    html {
      font-size: 2.5vw; } }

body {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #202020;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased; }
  @media screen and (min-width: 1400px) {
    body {
      padding-left: 10rem; } }
  @media screen and (max-width: 767px) {
    body {
      padding-left: 0;
      font-size: 1.4rem;
      line-height: 1.5; } }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:hover,
*:focus {
  outline: none; }

main {
  display: block; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input:not([type='radio']):not([type='checkbox']),
select,
textarea {
  width: 100%;
  font-size: 1.6rem;
  border: solid 1px #cccccc;
  line-height: 1.3;
  display: inline-block;
  padding: .7em .75em; }
  input:not([type='radio']):not([type='checkbox']):focus,
  select:focus,
  textarea:focus {
    outline: none; }

::placeholder {
  color: #cccccc; }

select {
  padding: .75em 1.5em .75em .75em; }
  .ios select {
    -webkit-appearance: button;
    appearance: button; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea {
  line-height: 1;
  vertical-align: middle; }
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="url"]:focus,
  input[type="search"]:focus,
  textarea:focus {
    outline: none;
    background: #d4ebf7; }

textarea {
  min-height: 12.5em; }
  @media screen and (max-width: 767px) {
    textarea {
      min-height: 10em; } }

img {
  vertical-align: middle; }

table {
  width: 100%; }

a {
  color: #333;
  text-decoration: none; }
  @media screen and (min-width: 992px) {
    a {
      transition: all 0.6s; } }

svg {
  width: 1em;
  height: 1em;
  fill: #202020; }

h1, h2, h3, h4, h5, h6, th, dt, strong, b {
  font-weight: 400;
  line-height: 1; }

@-ms-viewport {
  width: device-width; }

[role="button"] {
  cursor: pointer; }

.hidden {
  display: none !important; }

.show {
  display: block !important; }

.visible-ss,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-ss-i,
.visible-xs-i,
.visible-sm-i,
.visible-md-i,
.visible-lg-i {
  display: none !important; }

.visible-ss-ib,
.visible-xs-ib,
.visible-sm-ib,
.visible-md-ib,
.visible-lg-ib {
  display: none !important; }

@media screen and (max-width: 575px) {
  .visible-ss {
    display: block !important; }
  table.visible-ss {
    display: table !important; }
  tr.visible-ss {
    display: table-row !important; }
  th.visible-ss,
  td.visible-ss {
    display: table-cell !important; }
  .visible-ss-i {
    display: inline !important; }
  .visible-ss-ib {
    display: inline-block !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; }
  .visible-xs-i {
    display: inline !important; }
  .visible-xs-ib {
    display: inline-block !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  .visible-sm-i {
    display: inline !important; }
  .visible-sm-ib {
    display: inline-block !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; }
  .visible-md-i {
    display: inline !important; }
  .visible-md-ib {
    display: inline-block !important; } }

@media screen and (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; }
  .visible-lg-i {
    display: inline !important; }
  .visible-lg-ib {
    display: inline-block !important; } }

@media screen and (min-width: 1400px) {
  .visible-w1400 {
    display: block !important; } }

@media screen and (max-width: 575px) {
  .hidden-ss {
    display: none !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

@media screen and (max-width: 1399px) {
  .hidden-w1400 {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-sp {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .visible-tb {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .visible-pc {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-sp {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important; } }

/*--------------------------------------------
  plugins
--------------------------------------------*/
/*
SP MENU SAMPLE - HTML BTN + JS
------------------------------

<nav class="slideout-menu" id="js-so_menu">
  <div class="menu-inner">
  </div>
</nav>

<div class="slideout-panel" id="js-so_panel"></div>

<header></header>


<button type="button" class="slideout-hamburger" id="js-so-toggle">
  <span class="sr-only">MENU</span>
  <span class="hamburger-icon"></span>
</button>


// slideout JS
var slideout = new Slideout({
  'panel': document.getElementById('js-so_panel'),
  'menu': document.getElementById('js-so_menu'),
  'duration': 10,
});
var nodeList = document.querySelectorAll('#js-so-toggle, #js-so_panel');
var node = Array.prototype.slice.call(nodeList,0);
node.forEach(function(elem, index){
  elem.addEventListener('click', function() {
    slideout.toggle();
  });
});
*/
.slideout-open,
.slideout-open body {
  overflow: hidden; }

.slideout-menu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 101;
  width: 100%;
  background-color: #333;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1); }

.slideout-open .slideout-menu {
  right: 0; }

.slideout-menu .menu-inner {
  padding: 6em 1.5em; }

.slideout-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  will-change: transform;
  transform: translateX(0) !important;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none; }

.slideout-open .slideout-panel {
  z-index: 100;
  opacity: 1;
  pointer-events: auto; }

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.slideout-hamburger {
  display: block;
  width: 4.5em;
  height: 100%;
  padding: 2em 1em;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #222;
  cursor: pointer; }

.hamburger-icon {
  position: relative;
  display: block; }

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 100%;
  height: .2rem;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #fff; }

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  position: absolute;
  top: -.6em;
  left: 0; }

.hamburger-icon::after {
  top: .6em; }

.slideout-open .hamburger-icon {
  background-color: transparent; }

.slideout-open .hamburger-icon::before,
.slideout-open .hamburger-icon::after {
  top: 0; }

.slideout-open .hamburger-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.slideout-open .hamburger-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/plugins/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*--------------------------------------------
  module
--------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?weatbq");
  src: url("../fonts/icomoon.eot?weatbq#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?weatbq") format("truetype"), url("../fonts/icomoon.woff?weatbq") format("woff"), url("../fonts/icomoon.svg?weatbq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 92%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .c-inner.-full {
    width: 100%; }

@media screen and (min-width: 992px) {
  .c-multicol {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .c-multicol__title {
      width: 33%; }
    .c-multicol__content {
      width: 66%; } }

.c-under_key {
  position: relative; }
  .c-under_key__inner {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    z-index: 2;
    transform: translateX(-50%); }
  .c-under_key .key_img {
    position: relative;
    width: 100%;
    padding-top: 27.5rem;
    z-index: 1; }
  .c-under_key .key_txt {
    position: absolute;
    font-size: 8rem;
    line-height: 1;
    color: #fff;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    align-items: center; }
    .c-under_key .key_txt .ttl_en {
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
      padding-bottom: .25em; }
    .c-under_key .key_txt .ttl_ja {
      font-size: .2em;
      margin-left: 1.25em;
      font-weight: 400; }
  @media screen and (max-width: 991px) {
    .c-under_key .key_img {
      padding-top: 25rem; }
    .c-under_key .key_txt {
      font-size: 6rem; }
      .c-under_key .key_txt .ttl_ja {
        font-size: .25em; } }
  @media screen and (max-width: 767px) {
    .c-under_key .key_img {
      padding-top: 20rem; }
    .c-under_key .key_txt {
      display: block;
      font-size: 5rem; }
      .c-under_key .key_txt .ttl_ja {
        margin-top: .25em;
        margin-left: 0;
        font-size: .4em; } }

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-lead_box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
  margin-bottom: 6rem; }
  .c-lead_box .ttl_ja {
    position: relative;
    font-weight: 400;
    padding-left: 1.5em;
    margin-right: 4em;
    white-space: nowrap; }
    .c-lead_box .ttl_ja:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: #dc252b;
      transform: scale(0.375); }
  .c-lead_box .ttl_en {
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
    font-size: 5em;
    line-height: 1;
    margin-bottom: .4em; }
    .c-lead_box .ttl_en.-mgb_sm {
      margin-bottom: 0; }
    .c-lead_box .ttl_en span {
      display: inline-block;
      color: #dc252b;
      background: -webkit-linear-gradient(0deg, #264995, #a43185, #dc252b);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: .25em; }
  .c-lead_box p {
    line-height: 2.25; }
  .c-lead_box.-white {
    color: #fff; }
    .c-lead_box.-white .ttl_ja:before {
      background: #fff; }
    .c-lead_box.-white .ttl_en span {
      background: none;
      color: #fff;
      -webkit-text-fill-color: #fff;
      background-clip: none; }
  @media screen and (max-width: 991px) {
    .c-lead_box {
      display: block;
      margin-bottom: 4rem; }
      .c-lead_box .ttl_ja {
        margin-right: 0;
        margin-bottom: .75em; }
      .c-lead_box .ttl_en {
        font-size: 4.2em;
        margin-bottom: .3em; }
      .c-lead_box p {
        line-height: 1.8; } }
  @media screen and (max-width: 767px) {
    .c-lead_box .ttl_en {
      font-size: 3.6em; }
    .c-lead_box p {
      line-height: 1.8; } }

.c-num_ttl {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3rem; }
  .c-num_ttl .number {
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
    font-size: 9rem;
    line-height: 1; }
  .c-num_ttl .ttl {
    font-size: 1.6rem;
    margin-left: 1em;
    padding-top: .75em; }
    .c-num_ttl .ttl .en {
      font-size: 3em;
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
      line-height: 1;
      padding-bottom: .325em; }
    .c-num_ttl .ttl h3 {
      font-weight: 400;
      line-height: 1.4; }
  @media screen and (max-width: 1399px) {
    .c-num_ttl .number {
      font-size: 8rem; } }
  @media screen and (max-width: 767px) {
    .c-num_ttl {
      margin-bottom: 2rem; }
      .c-num_ttl .number {
        font-size: 7rem; }
      .c-num_ttl .ttl {
        font-size: 1.4rem; } }

.c-ttl01 {
  font-size: 4.8rem;
  line-height: 1; }
  .c-ttl01 .ttl_en {
    margin-bottom: .5em;
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
  .c-ttl01 .ttl_ja {
    font-size: .33333em;
    font-weight: 400; }
  @media screen and (max-width: 991px) {
    .c-ttl01 {
      font-size: 3.6rem; }
      .c-ttl01 .ttl_ja {
        font-size: .415em; } }

.c-ttl02 {
  text-align: center;
  line-height: 1;
  font-size: 4.8rem;
  margin-bottom: 1em; }
  .c-ttl02 .ttl_en {
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
    line-height: 1; }
    .c-ttl02 .ttl_en span {
      display: inline-block;
      color: #dc252b;
      background: -webkit-linear-gradient(0deg, #264995, #a43185, #dc252b);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .c-ttl02 .ttl_ja {
    font-size: .33333333em;
    font-weight: 400;
    margin-top: 1em; }
  @media screen and (max-width: 991px) {
    .c-ttl02 {
      font-size: 3.6rem; }
      .c-ttl02 .ttl_ja {
        font-size: .415em; } }

.c-ttl03 {
  line-height: 1;
  font-size: 4rem;
  margin-bottom: 1em; }
  .c-ttl03 .ttl_en {
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
  .c-ttl03 .ttl_ja {
    position: relative;
    font-size: .417em;
    font-weight: 400;
    padding-left: 1.5em;
    margin-bottom: 1.5em; }
    .c-ttl03 .ttl_ja:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      background: #dc252b;
      border-radius: 50%;
      transform: scale(0.375); }
  @media screen and (max-width: 991px) {
    .c-ttl03 {
      margin-bottom: .5em;
      font-size: 3.6rem; }
      .c-ttl03 .ttl_ja {
        font-size: .415em;
        margin-bottom: 1em; } }

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  text-align: center; }
  .c-btnwrap.-flex .btn {
    width: calc((100% - 4rem) / 2); }
    .c-btnwrap.-flex .btn a {
      display: block; }
    @media screen and (min-width: 768px) {
      .c-btnwrap.-flex .btn:nth-child(n+2) {
        margin-left: 4rem; } }
    @media screen and (max-width: 767px) {
      .c-btnwrap.-flex .btn:nth-child(n+2) {
        margin-top: 2rem; } }
  @media screen and (max-width: 767px) {
    .c-btnwrap {
      display: block; }
      .c-btnwrap.-flex .btn {
        width: 100%; } }

.c-btn01 {
  line-height: 1;
  text-align: center; }
  .c-btn01 a {
    position: relative;
    display: inline-block;
    padding: 1.375em 2em;
    min-width: 300px;
    background: -webkit-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background: -o-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background: linear-gradient(to right, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background-size: 300% 100%;
    background-position: right center;
    color: #fff;
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
    .c-btn01 a span {
      position: relative;
      display: block; }
    @media screen and (min-width: 992px) {
      .c-btn01 a:hover {
        background-position: left center; } }
  .c-btn01.-blue a {
    background: -webkit-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background: -o-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background: linear-gradient(to right, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background-size: 300% 100%;
    background-position: right center; }
    @media screen and (min-width: 992px) {
      .c-btn01.-blue a:hover {
        background-position: left center; } }
  @media screen and (max-width: 991px) {
    .c-btn01 a {
      padding: 1.25em 2em; } }
  @media screen and (max-width: 575px) {
    .c-btn01 a {
      min-width: 50%; } }

.c-link01 {
  line-height: 1; }

/*--------------------------------------------
  LIST
--------------------------------------------*/
.c-dot_list li {
  position: relative;
  padding-left: 1em;
  line-height: 1.6; }
  .c-dot_list li:before {
    position: absolute;
    content: "";
    top: .8em;
    left: 0;
    width: .4em;
    height: 1px;
    background: #202020; }
  .c-dot_list li:nth-child(n+2) {
    margin-top: .5em; }

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tblbox .sheet01 tr {
  border-bottom: solid 1px #f1f1f1; }

.c-tblbox .sheet01 th, .c-tblbox .sheet01 td {
  padding: 1.875em 0;
  text-align: left;
  vertical-align: top; }

.c-tblbox .sheet01 th {
  width: 20%;
  color: #bababa;
  line-height: 2; }
  .c-tblbox .sheet01 th .cell_in {
    font-size: 1.125em; }

.c-tblbox .sheet01 td {
  width: 80%;
  line-height: 2.25; }
  .c-tblbox .sheet01 td .cell_in {
    padding-top: .125em; }

@media screen and (max-width: 991px) {
  .c-tblbox .sheet01 th {
    width: 20%;
    color: #bababa; } }

@media screen and (max-width: 575px) {
  .c-tblbox .sheet01 tr {
    display: block;
    padding-bottom: 1.5em; }
    .c-tblbox .sheet01 tr:nth-child(n+2) {
      margin-top: 1.5em; }
  .c-tblbox .sheet01 th,
  .c-tblbox .sheet01 td {
    display: block;
    padding: 0;
    width: 100%; }
  .c-tblbox .sheet01 th {
    line-height: 1; }
  .c-tblbox .sheet01 td {
    line-height: 1.6;
    margin-top: .75em; } }

@media screen and (max-width: 767px) {
  .c-rwdsheet:after {
    display: block;
    content: "※横にスクロールできます";
    text-align: center; }
  .c-rwdsheet__inner {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .c-rwdsheet__inner::-webkit-scrollbar {
      height: .6rem; }
    .c-rwdsheet__inner::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .c-rwdsheet__inner::-webkit-scrollbar-thumb {
      background: #dc252b; }
  .c-rwdsheet table {
    width: auto !important;
    min-width: 100%; }
    .c-rwdsheet table th,
    .c-rwdsheet table td {
      width: auto !important;
      white-space: nowrap; } }

/*--------------------------------------------
  FORM
--------------------------------------------*/
.c-form_box .sheet01 {
  width: 80%;
  margin: 0 auto; }
  .c-form_box .sheet01 tr:nth-child(n+2) {
    border-top: solid 1px #ccc; }
  .c-form_box .sheet01 th,
  .c-form_box .sheet01 td {
    padding: 1.25em 1em; }
  .c-form_box .sheet01 th {
    width: 25%; }
  .c-form_box .sheet01 td {
    width: 75%; }
    .c-form_box .sheet01 td .mwform-file-delete {
      display: none; }
  .c-form_box .sheet01 .form_label {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .c-form_box .sheet01 .form_label.-required:after {
      content: "*";
      font-size: 1em;
      color: #c11;
      margin-left: .5em; }
  .c-form_box .sheet01 .form_item .i_input.-short {
    width: 100%; }
  .c-form_box .sheet01 .form_item .i_input.-middle {
    width: 100%; }
  .c-form_box .sheet01 .form_item .i_input.-long {
    width: 100%; }
  .c-form_box .sheet01 .form_item .i_input .error {
    color: #c11;
    margin-top: .5em;
    font-size: .875em; }

.c-form_box .form_btn {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; }
  .c-form_box .form_btn a,
  .c-form_box .form_btn input[type="button"],
  .c-form_box .form_btn input[type="submit"] {
    padding: 1.375em 2em;
    width: 280px;
    background: -webkit-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background: -o-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background: linear-gradient(to right, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
    background-size: 300% 100%;
    background-position: right center;
    border: none;
    outline: none;
    color: #fff;
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
    cursor: pointer;
    transition: all 0.6s;
    text-align: center; }
    .c-form_box .form_btn a.btn_back,
    .c-form_box .form_btn input[type="button"].btn_back,
    .c-form_box .form_btn input[type="submit"].btn_back {
      background: #999;
      margin-left: 1em; }
    @media screen and (min-width: 992px) {
      .c-form_box .form_btn a:hover,
      .c-form_box .form_btn input[type="button"]:hover,
      .c-form_box .form_btn input[type="submit"]:hover {
        background-position: left center; }
      .c-form_box .form_btn a.btn_back,
      .c-form_box .form_btn input[type="button"].btn_back,
      .c-form_box .form_btn input[type="submit"].btn_back {
        background: #999; }
        .c-form_box .form_btn a.btn_back:hover,
        .c-form_box .form_btn input[type="button"].btn_back:hover,
        .c-form_box .form_btn input[type="submit"].btn_back:hover {
          opacity: 5; } }
  .c-form_box .form_btn.-blue input[type="submit"] {
    background: -webkit-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background: -o-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background: linear-gradient(to right, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
    background-size: 300% 100%;
    background-position: right center; }
    .c-form_box .form_btn.-blue input[type="submit"]:hover {
      opacity: 1;
      background-position: left center; }

.c-form_box.-thanks h2 {
  text-align: center;
  font-size: 1.75em;
  margin-bottom: 1.5em; }

.c-form_box.-thanks p {
  text-align: center;
  line-height: 2.5; }

@media screen and (max-width: 991px) {
  .c-form_box .sheet01 {
    width: 100%; }
    .c-form_box .sheet01 th {
      width: 30%; }
    .c-form_box .sheet01 td {
      width: 70%; } }

@media screen and (max-width: 575px) {
  .c-form_box .sheet01 {
    width: 100%; }
    .c-form_box .sheet01 tr {
      display: block;
      padding: 1.5em 0; }
    .c-form_box .sheet01 th,
    .c-form_box .sheet01 td {
      display: block;
      width: 100%;
      padding: 0; }
    .c-form_box .sheet01 th {
      margin-bottom: 1em; }
  .c-form_box .form_btn {
    margin-top: 1.5em; }
    .c-form_box .form_btn input[type="button"],
    .c-form_box .form_btn input[type="submit"] {
      display: block;
      margin: 0 auto; }
      .c-form_box .form_btn input[type="button"].btn_back,
      .c-form_box .form_btn input[type="submit"].btn_back {
        margin-left: auto;
        margin-top: 1em; } }

/*--------------------------------------------
  PARTS - NEWS
--------------------------------------------*/
.c-news_list li {
  margin-top: -1px; }
  .c-news_list li a {
    position: relative;
    padding: 3rem 5rem;
    align-items: stretch;
    line-height: 1;
    border-bottom: solid 1px #f1f1f1;
    border-top: solid 1px #f1f1f1;
    margin-top: -1px; }
    .c-news_list li a:before {
      position: absolute;
      left: initial;
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      content: "";
      background: #f1f1f1;
      transition: .6s;
      z-index: 1; }
    .c-news_list li a > * {
      position: relative;
      z-index: 2; }
    .c-news_list li a time {
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
      font-size: 1.8rem;
      padding: .3rem 0;
      color: #bababa;
      width: 7em; }
    .c-news_list li a .title {
      line-height: 1.5;
      width: calc(100% - 7em * 1.125); }
    @media screen and (min-width: 992px) {
      .c-news_list li a:hover:before {
        width: 100%;
        left: 0;
        right: initial; } }

@media screen and (max-width: 767px) {
  .c-news_list li a {
    padding: 2rem 1.5rem;
    display: block; }
    .c-news_list li a time {
      width: 100%;
      display: block; }
    .c-news_list li a .title {
      width: 100%;
      display: block; } }

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 62.5%;
  height: 0;
  overflow: hidden; }
  .c-gmap iframe,
  .c-gmap object,
  .c-gmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 767px) {
    .c-gmap {
      padding-top: 125%; } }

/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden; }
  .c-img_ofi .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none; }
    .c-img_ofi .ofi.cover {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;'; }
    .c-img_ofi .ofi.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;'; }

/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto; }

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-ta_left {
  text-align: left; }

.c-ta_center {
  text-align: center; }

.c-ta_right {
  text-align: right; }

.c-pe_none {
  pointer-events: none; }

.c-flex {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

.c-mgt0 {
  margin-top: 0 !important; }

@media screen and (min-width: 992px) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default; } }

/*-----------------------------------------------------
  Wordpress EDITOR
------------------------------------------------------*/
.wp-editor {
  line-height: 1.875; }
  .wp-editor:not(:last-child) {
    margin-bottom: 6rem; }
  .wp-editor * + p,
  .wp-editor * + ul,
  .wp-editor * + ol,
  .wp-editor * + .c-rwdsheet {
    margin-top: 1em; }
  .wp-editor * + h2,
  .wp-editor * + h3,
  .wp-editor * + h4 {
    margin-top: 2em; }
  .wp-editor h2 {
    font-size: 1.75em;
    line-height: 1.3; }
  .wp-editor h3 {
    font-size: 1.5em;
    line-height: 1.3; }
  .wp-editor h4 {
    font-size: 1.25em;
    line-height: 1.3; }
  .wp-editor strong {
    font-weight: 700; }
  .wp-editor em {
    font-style: italic; }
  .wp-editor blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px; }
  .wp-editor img {
    display: block; }
    .wp-editor img.alignleft {
      margin: 0 auto 0 0; }
    .wp-editor img.aligncenter {
      margin: 0 auto; }
    .wp-editor img.alignright {
      margin: 0 0 0 auto; }
    .wp-editor img[class*="attachment-"], .wp-editor img[class*="wp-image-"] {
      height: auto;
      max-width: 100%; }
  .wp-editor hr {
    border-top-color: #bababa;
    margin: 2em 0; }
  .wp-editor a {
    color: #264995;
    text-decoration: underline;
    word-break: break-all; }
    @media screen and (min-width: 992px) {
      .wp-editor a:hover, .wp-editor a:focus {
        opacity: .7;
        text-decoration: none; } }
  .wp-editor ul,
  .wp-editor ol {
    line-height: 1.6; }
    .wp-editor ul > li,
    .wp-editor ol > li {
      position: relative;
      padding-left: 1.5em; }
      .wp-editor ul > li:not(:first-child),
      .wp-editor ol > li:not(:first-child) {
        margin-top: .6em; }
      .wp-editor ul > li:before,
      .wp-editor ol > li:before {
        position: absolute;
        content: "";
        left: 0; }
  .wp-editor ul > li:before {
    top: .3em;
    width: 1em;
    height: 1em;
    background: #dc252b;
    border-radius: 50%;
    transform: scale(0.375); }
  .wp-editor ol {
    counter-reset: list_count01 0; }
    .wp-editor ol > li {
      padding-left: 1.5em;
      counter-increment: list_count01 1; }
      .wp-editor ol > li:before {
        top: 0;
        content: counter(list_count01) ".";
        width: 1.5em;
        padding-right: .25em;
        text-align: right;
        color: #dc252b; }
  .wp-editor table {
    width: 100%; }
    .wp-editor table th,
    .wp-editor table td {
      padding: 1em;
      border: 1px solid #ddd; }
    .wp-editor table th {
      background-color: #bababa; }
  @media screen and (max-width: 991px) {
    .wp-editor:not(:last-child) {
      margin-bottom: 4rem; }
    .wp-editor * + p,
    .wp-editor * + ul,
    .wp-editor * + ol,
    .wp-editor * + .c-rwdsheet {
      margin-top: 1.5em; }
    .wp-editor * + h2,
    .wp-editor * + h3,
    .wp-editor * + h4 {
      margin-top: 2.5em; }
    .wp-editor h2 {
      font-size: 1.5em; }
    .wp-editor h3 {
      font-size: 1.35em; }
    .wp-editor h4 {
      font-size: 1.2em; } }

/*-----------------------------------------------------
  Wordpress Pagination
------------------------------------------------------*/
.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center; }
  .wp-pagenavi > a,
  .wp-pagenavi > span {
    display: block;
    width: 2em;
    line-height: 2;
    text-align: center; }
  .wp-pagenavi .current {
    background: #202020;
    color: #fff;
    text-decoration: underline; }
  .wp-pagenavi a {
    background: #dc252b;
    color: #fff; }
    .wp-pagenavi a:hover {
      background: #264995; }
  .wp-pagenavi > *:nth-child(n+2) {
    margin-left: .5em; }

/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
@media screen and (min-width: 1400px) {
  .l-main {
    padding-top: 6.25em; } }

.l-breadcrumb {
  padding: 1em 0;
  line-height: 1; }
  .l-breadcrumb__list {
    justify-content: flex-start;
    align-items: center; }
    .l-breadcrumb__list li {
      position: relative;
      padding-right: 2em; }
      .l-breadcrumb__list li:after {
        position: absolute;
        content: "";
        right: .5em;
        top: 50%;
        width: 1em;
        height: 1px;
        background: #b7b8b9; }
      .l-breadcrumb__list li a {
        position: relative;
        padding: 5px 0;
        display: inline-block; }
        .l-breadcrumb__list li a:after {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: #202020;
          transition: all 0.6s; }
        .l-breadcrumb__list li a:hover {
          color: #b7b8b9; }
          .l-breadcrumb__list li a:hover:after {
            background: #b7b8b9;
            transform: scaleX(0); }
      .l-breadcrumb__list li:last-child {
        padding-right: 0; }
        .l-breadcrumb__list li:last-child:after {
          display: none; }
        .l-breadcrumb__list li:last-child a {
          pointer-events: none;
          color: #b7b8b9; }
          .l-breadcrumb__list li:last-child a:after {
            display: none; }

/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 120;
  background: #fff;
  width: 100%;
  justify-content: space-between;
  align-items: center; }
  .l-header__logo {
    position: relative;
    z-index: 111;
    padding-left: 3rem; }
    .l-header__logo a {
      display: block; }
      .l-header__logo a img {
        max-width: 100%;
        height: auto; }
  .l-header__toggle {
    position: fixed;
    z-index: 110;
    left: 0;
    top: 0;
    width: 10rem;
    height: 100vh;
    background: #fff;
    transition: all 0.6s; }
    .l-header__toggle .so_toggle {
      position: absolute;
      background: transparent;
      width: 3.6rem;
      height: 3.6rem;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .l-header__toggle .so_toggle .hamburger-icon {
        background: transparent;
        top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before, .l-header__toggle .so_toggle .hamburger-icon:after {
          background: #202020;
          top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before {
          transform: translateY(-4px); }
        .l-header__toggle .so_toggle .hamburger-icon:after {
          transform: translateY(4px); }
    .l-header__toggle .totop {
      position: absolute;
      left: 50%;
      bottom: 3rem;
      line-height: 1;
      writing-mode: vertical-rl;
      padding-bottom: 7rem;
      transform: translateX(-50%); }
      .l-header__toggle .totop i {
        position: absolute;
        right: 50%;
        bottom: 0;
        width: 1px;
        height: 5rem;
        background: #202020; }
        .l-header__toggle .totop i:before {
          position: absolute;
          content: "";
          left: -2px;
          top: 1rem;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #202020;
          transition: all 0.6s; }
      .l-header__toggle .totop:hover {
        opacity: 0.5; }
        .l-header__toggle .totop:hover i:before {
          top: calc(100% - 1rem); }
  .slideout-open .l-header__logo img {
    visibility: hidden; }
  .slideout-open .l-header__toggle {
    background: rgba(255, 255, 255, 0); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before, .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      background: #fff; }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before {
      transform: rotate(45deg); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      transform: rotate(-45deg); }
    .slideout-open .l-header__toggle .totop {
      display: none; }
  @media screen and (max-width: 1399px) {
    .l-header {
      padding: 2rem;
      position: static; }
      .l-header__logo {
        position: static;
        padding-left: 1;
        padding-left: 0; }
        .l-header__logo a img {
          height: 4.5rem; }
      .l-header__toggle {
        position: relative;
        width: 5rem;
        height: 5rem;
        background: transparent; }
        .l-header__toggle .so_toggle {
          position: absolute;
          width: 72%;
          height: 72%; } }

/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi {
  justify-content: flex-end;
  align-items: center;
  line-height: 1; }
  .l-gnavi li:nth-child(n+2) {
    margin-left: 1.875em; }
  .l-gnavi li a {
    position: relative;
    display: block; }
    .l-gnavi li a span {
      padding: 5px 0;
      display: block; }
    .l-gnavi li a:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #202020;
      transform: scaleX(0);
      transition: all 0.6s; }
    .l-gnavi li a:hover {
      opacity: .75; }
      .l-gnavi li a:hover:after {
        transform: scaleX(1); }
    .l-gnavi li a.contact_btn {
      position: relative;
      padding: 2.625em 1.875em;
      background: -webkit-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
      background: -o-linear-gradient(left, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
      background: linear-gradient(to right, #264995 0%, #264995 34%, #a43185 50%, #dc252b 66%, #dc252b 100%);
      background-size: 300% 100%;
      background-position: right center;
      color: #fff;
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
      .l-gnavi li a.contact_btn span {
        position: relative;
        display: block;
        padding: 0 0 0 1.625em; }
        .l-gnavi li a.contact_btn span:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e900"; }
      .l-gnavi li a.contact_btn:after {
        display: none; }
      .l-gnavi li a.contact_btn:hover {
        opacity: 1;
        background-position: left center; }
      .l-gnavi li a.contact_btn.-blue {
        background: -webkit-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
        background: -o-linear-gradient(left, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
        background: linear-gradient(to right, #dc252b 0%, #dc252b 34%, #a43185 50%, #264995 66%, #264995 100%);
        background-size: 300% 100%;
        background-position: right center; }
        .l-gnavi li a.contact_btn.-blue:hover {
          opacity: 1;
          background-position: left center; }

.l-so_menu {
  background: -webkit-linear-gradient(left, #264995 0%, #a43185 50%, #dc252b 100%);
  background: -o-linear-gradient(left, #264995 0%, #a43185 50%, #dc252b 100%);
  background: linear-gradient(to right, #264995 0%, #a43185 50%, #dc252b 100%); }
  @media screen and (min-width: 992px) {
    .l-so_menu {
      padding-left: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; } }
  .l-so_menu dl {
    line-height: 1;
    color: #fff;
    font-size: 2.8rem; }
    .l-so_menu dl:nth-child(n+2) {
      margin-left: 8rem; }
    .l-so_menu dl a {
      position: relative;
      color: #fff;
      padding: 5px 0;
      display: inline-block; }
      .l-so_menu dl a:before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        left: 0;
        background: #fff;
        transition: all 0.6s; }
      @media screen and (min-width: 992px) {
        .l-so_menu dl a:hover {
          opacity: .5; }
          .l-so_menu dl a:hover:before {
            width: 100%; } }
    .l-so_menu dl dt {
      font-size: 1em; }
      .l-so_menu dl dt:nth-child(n+2) {
        margin-top: 1em; }
    .l-so_menu dl dd {
      font-size: .85em;
      margin-top: 1em; }
      .l-so_menu dl dd li {
        position: relative;
        padding-left: 1em; }
        .l-so_menu dl dd li:nth-child(n+2) {
          margin-top: .5em; }
        .l-so_menu dl dd li:before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 50%;
          width: .5em;
          height: 1px;
          background: #fff; }
  @media screen and (max-width: 1199px) {
    .l-so_menu dl {
      font-size: 2.4rem; } }
  @media screen and (max-width: 991px) {
    .l-so_menu {
      padding: 8rem; }
      .l-so_menu .inner {
        display: block; }
      .l-so_menu dl:nth-child(n+2) {
        margin-left: 0;
        margin-top: 1em; }
      .l-so_menu dl dd {
        padding-left: 1em; } }
  @media screen and (max-width: 767px) {
    .l-so_menu {
      padding: 6rem; } }
  @media screen and (max-width: 575px) {
    .l-so_menu {
      background: -webkit-linear-gradient(-45deg, #264995 0%, #a43185 50%, #dc252b 100%);
      background: -o-linear-gradient(-45deg, #264995 0%, #a43185 50%, #dc252b 100%);
      background: linear-gradient(135deg, #264995 0%, #a43185 50%, #dc252b 100%);
      padding: 8rem 4%; }
      .l-so_menu dl {
        font-size: 2rem; } }

/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer_top {
  background: #202020;
  padding: 10rem 0; }
  .l-footer_top__inner {
    justify-content: space-between; }
  .l-footer_top__info .logo {
    margin-bottom: 3rem; }
  .l-footer_top__info .address {
    color: #fff;
    font-size: 1.4rem;
    line-height: 1.71; }
  .l-footer_top__menu {
    line-height: 1; }
    .l-footer_top__menu dl:nth-child(n+2) {
      margin-left: 10rem; }
    .l-footer_top__menu dl dt {
      color: #fff; }
      .l-footer_top__menu dl dt:not(:first-child) {
        margin-top: calc(1.875em - 5px); }
    .l-footer_top__menu dl dd {
      margin-top: 1.5em;
      font-size: 1.4rem; }
      .l-footer_top__menu dl dd li {
        position: relative;
        padding-left: 1em;
        color: #fff; }
        .l-footer_top__menu dl dd li:nth-child(n+2) {
          margin-top: calc(1.5em - 6px); }
        .l-footer_top__menu dl dd li:before {
          position: absolute;
          content: "";
          width: .5em;
          height: 1px;
          left: 0;
          bottom: 50%;
          background: #fff; }
    .l-footer_top__menu dl a {
      position: relative;
      display: inline-block;
      color: #fff;
      padding: 5px 0; }
      .l-footer_top__menu dl a:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 0%;
        height: 1px;
        background: #fff;
        transition: all 0.6s; }
      @media screen and (min-width: 992px) {
        .l-footer_top__menu dl a:hover:before {
          width: 100%; } }
  @media screen and (max-width: 1399px) {
    .l-footer_top__info .logo img {
      height: 10rem; } }
  @media screen and (max-width: 991px) {
    .l-footer_top {
      padding: 4rem 0; }
      .l-footer_top__inner {
        display: block; }
      .l-footer_top__info {
        display: flex;
        justify-content: center;
        align-items: center; }
        .l-footer_top__info .logo {
          margin-bottom: 0;
          margin-right: 3rem; }
          .l-footer_top__info .logo img {
            height: 8rem; }
        .l-footer_top__info .address {
          font-size: 1.6rem; } }

.l-footer_bottom {
  padding: 3rem 0;
  background: #000; }
  .l-footer_bottom__inner {
    justify-content: space-between;
    align-items: center; }
  .l-footer_bottom__menu {
    font-size: 1.4rem;
    line-height: 1; }
    .l-footer_bottom__menu li a {
      position: relative;
      color: #fff;
      padding: 3px 0; }
      .l-footer_bottom__menu li a:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background: #fff;
        transition: all 0.6s; }
      @media screen and (min-width: 992px) {
        .l-footer_bottom__menu li a:hover:before {
          width: 100%; } }
  .l-footer_bottom__copy {
    line-height: 1;
    color: #fff;
    font-size: 1.4rem;
    font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
  @media screen and (max-width: 991px) {
    .l-footer_bottom {
      padding: 2rem 0; }
      .l-footer_bottom__inner {
        justify-content: center; } }

/*--------------------------------------------
  PAGE
--------------------------------------------*/
/*--------------------------------------------
  INDEX
--------------------------------------------*/
.p-idx_key {
  position: relative; }
  .p-idx_key__inner {
    position: relative; }
  .p-idx_key .key_img {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: calc(100vh - 6.25em); }
  .p-idx_key .key_txt {
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    z-index: 2; }
    .p-idx_key .key_txt p {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto; }
      .p-idx_key .key_txt p.txt_01 {
        width: 74.195rem;
        height: 11.94rem; }
      .p-idx_key .key_txt p.txt_02 {
        width: 73.427rem;
        height: 8.7rem; }
      .p-idx_key .key_txt p.txt_03 {
        width: 103.739rem;
        height: 8.688rem;
        margin-top: 3rem; }
      .p-idx_key .key_txt p svg {
        width: 100%;
        height: 100%;
        fill: #202020;
        fill-opacity: 0;
        stroke: #202020;
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
        stroke-width: 2;
        stroke-miterlimit: 10; }
  @media screen {
    .p-idx_key.is-anime .key_txt p svg {
      animation: draw_path01 1.4s ease-in forwards; }
    .p-idx_key.is-anime .key_txt p.txt_02 svg {
      animation: draw_path02 1.4s ease-in forwards; } }
  @media screen and (max-width: 1399px) {
    .p-idx_key .key_txt {
      transform: translate(-50%, -50%) scale(0.95); } }
  @media screen and (max-width: 991px) {
    .p-idx_key .key_img {
      padding-top: 75vw; }
    .p-idx_key .key_txt {
      transform: translate(-50%, -50%) scale(0.65); } }
  @media screen and (max-width: 767px) {
    .p-idx_key .key_img {
      padding-top: 75vw; }
    .p-idx_key .key_txt {
      transform: translate(-50%, -50%) scale(0.45); } }
  @media screen and (max-width: 575px) {
    .p-idx_key .key_img {
      padding-top: 75vw; }
    .p-idx_key .key_txt {
      transform: translate(-50%, -50%) scale(0.35); } }

.p-idx_advantage {
  padding: 15rem 0; }
  .p-idx_advantage__list {
    margin-bottom: 5rem; }
    .p-idx_advantage__list li {
      width: 50%; }
      .p-idx_advantage__list li figure {
        position: relative; }
        .p-idx_advantage__list li figure .image {
          padding-top: 42rem; }
          .p-idx_advantage__list li figure .image img {
            z-index: 1; }
          .p-idx_advantage__list li figure .image:before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, black 100%);
            z-index: 2; }
        .p-idx_advantage__list li figure .title {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          color: #fff;
          padding: 3rem; }
    .p-idx_advantage__list p {
      line-height: 2.25;
      padding: 1.875em 3rem; }
  @media screen {
    .p-idx_advantage__title {
      transition: all 0.6s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_advantage__list {
      transition: all 0.6s 0.3s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_advantage__button {
      transition: all 0.6s 0.9s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_advantage.is-anime .p-idx_advantage__title,
    .p-idx_advantage.is-anime .p-idx_advantage__list,
    .p-idx_advantage.is-anime .p-idx_advantage__button {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_advantage {
      padding: 8rem 0; }
      .p-idx_advantage__list {
        margin-bottom: 4rem; }
        .p-idx_advantage__list li figure .image {
          padding-top: 100%; }
        .p-idx_advantage__list li figure .title {
          padding: 2rem; }
        .p-idx_advantage__list p {
          font-size: 1.5rem;
          line-height: 1.8;
          padding: 1em 2rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_advantage__list {
      display: block; }
      .p-idx_advantage__list li {
        width: 100%; }
        .p-idx_advantage__list li:nth-child(n+2) {
          margin-top: 4rem; }
        .p-idx_advantage__list li figure .image {
          padding-top: 60%; } }
  @media screen and (max-width: 575px) {
    .p-idx_advantage__list {
      margin-bottom: 2rem; } }

.p-idx_cs {
  background: url("../img/index/idx_cs_bg.jpg") no-repeat center center/cover;
  padding: 15rem 0; }
  .p-idx_cs__list {
    justify-content: center;
    align-items: stretch; }
    .p-idx_cs__list li {
      width: 22.5%; }
      .p-idx_cs__list li:nth-child(n+2) {
        margin-left: 3.333333%; }
      .p-idx_cs__list li a {
        display: block;
        color: #fff; }
        .p-idx_cs__list li a .image {
          background: #fff;
          padding-top: 74%; }
        .p-idx_cs__list li a .text {
          border-bottom: solid 1px #fff;
          padding: 1.625em 0;
          line-height: 1;
          justify-content: space-between;
          align-items: center; }
          .p-idx_cs__list li a .text h3 {
            font-weight: 400; }
            .p-idx_cs__list li a .text h3 span {
              display: block; }
              .p-idx_cs__list li a .text h3 span.ttl_en {
                font-size: 1.5em;
                font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif; }
              .p-idx_cs__list li a .text h3 span.ttl_ja {
                margin-top: .875em; }
          .p-idx_cs__list li a .text .arrow {
            position: relative;
            width: 4.5rem;
            height: 4.5rem;
            display: inline-block;
            border: solid 1px #fff;
            transition: .6s; }
            .p-idx_cs__list li a .text .arrow:before {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) scale(0.75);
              font-family: 'icomoon' !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\f054"; }
        @media screen and (min-width: 992px) {
          .p-idx_cs__list li a:hover {
            opacity: .5; }
            .p-idx_cs__list li a:hover .text .arrow {
              background: #fff;
              color: #202020; } }
  @media screen {
    .p-idx_cs__title {
      transition: all 0.6s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_cs__list {
      transition: all 0.6s 0.3s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_cs.is-anime .p-idx_cs__title,
    .p-idx_cs.is-anime .p-idx_cs__list {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_cs {
      padding: 8rem 0; }
      .p-idx_cs__list {
        justify-content: space-between; }
        .p-idx_cs__list li {
          width: 48%; }
          .p-idx_cs__list li:nth-child(n+2) {
            margin-left: 0; }
          .p-idx_cs__list li:nth-child(n+3) {
            margin-top: 4%; }
          .p-idx_cs__list li a {
            display: block;
            color: #fff; }
            .p-idx_cs__list li a .image {
              padding-top: 60%;
              background: none; }
            .p-idx_cs__list li a .text {
              padding: 1.5em 0; }
              .p-idx_cs__list li a .text .arrow {
                width: 4rem;
                height: 4rem; } }

.p-idx_regard {
  padding-top: 15rem;
  overflow: hidden; }
  .p-idx_regard__slide {
    position: relative;
    padding-top: 8rem; }
  .p-idx_regard__img {
    position: absolute;
    right: 0;
    top: 0;
    width: 66.666666%;
    z-index: 2;
    background: #fff; }
    .p-idx_regard__img .img_slide .slide_in {
      padding-top: 62.5%; }
  .p-idx_regard__text {
    position: relative;
    z-index: 1;
    width: 66.666666%; }
    .p-idx_regard__text > * {
      position: relative;
      z-index: 2; }
    .p-idx_regard__text:before {
      position: absolute;
      right: 0;
      top: 0;
      content: "";
      background: #f1f1f1;
      width: 100vw;
      height: 100%; }
    .p-idx_regard__text .text_slide .slide_in {
      padding: 8rem calc(50% + 8rem) 8rem 0; }
      .p-idx_regard__text .text_slide .slide_in > * {
        position: relative; }
        .p-idx_regard__text .text_slide .slide_in > *:before {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #264995;
          z-index: 1;
          transition: all .4s .6s; }
      .p-idx_regard__text .text_slide .slide_in p {
        margin-bottom: 5rem; }
      .p-idx_regard__text .text_slide .slide_in .link_btn {
        text-align: left; }
        .p-idx_regard__text .text_slide .slide_in .link_btn a {
          text-align: center;
          min-width: 1px;
          width: 100%; }
    .p-idx_regard__text .text_slide.slick-current .slide_in > *:before {
      width: 0; }
  .p-idx_regard__control {
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    line-height: 1; }
    .p-idx_regard__control .counter {
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
      margin-right: 1.875em; }
      .p-idx_regard__control .counter .total {
        padding-left: .5em;
        color: #bababa; }
        .p-idx_regard__control .counter .total:before {
          content: "/";
          margin-right: .5em; }
    .p-idx_regard__control .controller .arrow {
      position: relative;
      width: 4.5rem;
      height: 4.5rem;
      color: #dc252b;
      border: solid 1px #dc252b;
      display: block;
      cursor: pointer;
      transition: all 0.6s; }
      .p-idx_regard__control .controller .arrow:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.75); }
      .p-idx_regard__control .controller .arrow.prev {
        margin-right: 1rem; }
        .p-idx_regard__control .controller .arrow.prev:before {
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f053"; }
      .p-idx_regard__control .controller .arrow.next:before {
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f054"; }
      @media screen and (min-width: 992px) {
        .p-idx_regard__control .controller .arrow:hover {
          background: #dc252b;
          color: #fff; } }
  @media screen {
    .p-idx_regard__title {
      transition: all 0.6s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_regard__slide {
      transition: all 0.6s 0.3s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_regard__control {
      transition: all 0.6s 0.9s;
      opacity: 0; }
    .p-idx_regard.is-anime .p-idx_regard__title,
    .p-idx_regard.is-anime .p-idx_regard__slide {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_regard.is-anime .p-idx_regard__control {
      opacity: 1; } }
  @media screen and (max-width: 1399px) {
    .p-idx_regard__text .text_slide .slide_in {
      padding: 5rem calc(50% + 4rem) 5rem 0; }
      .p-idx_regard__text .text_slide .slide_in p {
        margin-bottom: 3rem; } }
  @media screen and (max-width: 991px) {
    .p-idx_regard {
      padding-top: 8rem; }
      .p-idx_regard__slide {
        position: relative;
        padding-top: 0; }
      .p-idx_regard__img {
        position: relative;
        padding-bottom: 8rem;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        background: #fff; }
        .p-idx_regard__img .img_slide {
          position: relative;
          z-index: 2; }
          .p-idx_regard__img .img_slide .slide_in {
            width: 70vw;
            padding-top: 42.5%; }
        .p-idx_regard__img:before {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          width: 70vw;
          height: calc(100% - 8rem);
          background: #f1f1f1; }
      .p-idx_regard__text {
        position: relative;
        z-index: 1;
        width: 100%; }
        .p-idx_regard__text:before {
          display: none; }
        .p-idx_regard__text .text_slide .slide_in {
          padding: 4rem 0 0; }
          .p-idx_regard__text .text_slide .slide_in p {
            margin-bottom: 3rem; }
          .p-idx_regard__text .text_slide .slide_in .link_btn {
            text-align: center; }
            .p-idx_regard__text .text_slide .slide_in .link_btn a {
              min-width: 300px;
              width: auto; }
      .p-idx_regard__control {
        bottom: initial;
        top: 2rem;
        z-index: 3; }
        .p-idx_regard__control .counter {
          margin-right: 1em; }
        .p-idx_regard__control .controller .arrow {
          width: 4rem;
          height: 4rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_regard__img {
      position: static;
      padding-bottom: 0;
      width: 100%;
      margin-bottom: 3rem; }
      .p-idx_regard__img .img_slide {
        position: relative;
        z-index: 2; }
        .p-idx_regard__img .img_slide .slide_in {
          width: 100%;
          padding-top: 50%; }
      .p-idx_regard__img:before {
        display: none; }
    .p-idx_regard__text .text_slide .slide_in {
      padding: 0; }
      .p-idx_regard__text .text_slide .slide_in .link_btn a {
        min-width: 50%; }
    .p-idx_regard__text.slick-dotted.slick-slider {
      margin-bottom: 0; }
    .p-idx_regard__control {
      margin-top: 1em;
      position: static;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse; }
      .p-idx_regard__control .counter {
        display: none; }
      .p-idx_regard__control .controller .slick-dots {
        position: static;
        margin-bottom: 1em; }
        .p-idx_regard__control .controller .slick-dots li {
          width: .7em;
          height: .7em;
          margin: 0 .5em; }
          .p-idx_regard__control .controller .slick-dots li button {
            padding: 0;
            width: 100%;
            height: 100%;
            background: #202020;
            border-radius: 50%;
            opacity: .5; }
            .p-idx_regard__control .controller .slick-dots li button:before {
              display: none; }
          .p-idx_regard__control .controller .slick-dots li.slick-active button {
            opacity: 1; } }

.p-idx_news {
  padding: 15rem 0; }
  .p-idx_news__button {
    margin-top: 5rem; }
  @media screen and (max-width: 991px) {
    .p-idx_news {
      padding: 8rem 0; }
      .p-idx_news__button {
        margin-top: 4rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_news {
      padding: 6rem 0; } }
  @media screen {
    .p-idx_news__title {
      transition: all 0.6s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_news__list {
      transition: all 0.6s 0.3s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_news__button {
      transition: all 0.6s 0.9s;
      transform: translateY(6rem);
      opacity: 0; }
    .p-idx_news.is-anime .p-idx_news__title,
    .p-idx_news.is-anime .p-idx_news__list,
    .p-idx_news.is-anime .p-idx_news__button {
      transform: translateY(0);
      opacity: 1; } }

/*============================================

  PROCESS

==============================================*/
.p-process_main {
  padding: 10rem 0; }
  @media screen and (max-width: 991px) {
    .p-process_main {
      padding: 8rem 0; } }

.p-process_box {
  position: relative;
  background: #fafafa;
  padding: 8rem; }
  .p-process_box:nth-child(n+2) {
    margin-top: 10rem; }
    .p-process_box:nth-child(n+2):before {
      position: absolute;
      content: "";
      left: 50%;
      bottom: calc(100% + 2rem);
      border-style: solid;
      border-width: 6rem 6rem 0;
      border-color: #202020 transparent;
      transform: translateX(-50%); }
  @media screen and (max-width: 991px) {
    .p-process_box {
      padding: 6rem; }
      .p-process_box:nth-child(n+2) {
        margin-top: 8rem; }
        .p-process_box:nth-child(n+2):before {
          border-width: 4rem 4rem 0; } }
  @media screen and (max-width: 767px) {
    .p-process_box {
      padding: 4rem 4%; }
      .p-process_box:nth-child(n+2):before {
        bottom: calc(100% + 2rem);
        border-width: 4rem 4rem 0; } }

.p-process_list .arrow {
  position: relative;
  width: 2rem;
  margin: 0 auto;
  height: 5rem; }
  .p-process_list .arrow i {
    position: absolute;
    left: 50%;
    top: 1rem;
    width: .4rem;
    height: calc(100% - 2rem);
    transform: translateX(-50%);
    background: #202020; }
    .p-process_list .arrow i:before, .p-process_list .arrow i:after {
      position: absolute;
      content: "";
      bottom: 0;
      width: 1rem;
      height: .6rem;
      background: #202020; }
    .p-process_list .arrow i:before {
      left: .2rem;
      transform-origin: left bottom;
      transform: skewY(-35deg) translateY(0.2rem); }
    .p-process_list .arrow i:after {
      right: .2rem;
      transform-origin: right bottom;
      transform: skewY(35deg) translateY(0.2rem); }

.p-process_list__item .box {
  border: solid 1px #cdcdcd;
  line-height: 1.4;
  font-size: 2.4rem;
  padding: 1em;
  background: #fff;
  text-align: center; }
  .p-process_list__item .box span {
    font-size: .6666666em;
    margin-left: .5em; }
    .p-process_list__item .box span:before {
      content: "("; }
    .p-process_list__item .box span:after {
      content: ")"; }

.p-process_list__row {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch; }
  .p-process_list__row > .arrow {
    position: absolute;
    margin: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .p-process_list__row ul {
    width: 45%; }
    .p-process_list__row ul:first-child {
      margin-right: 10%; }

@media screen and (max-width: 991px) {
  .p-process_list__item .box {
    font-size: 2rem; }
    .p-process_list__item .box span {
      display: block;
      margin-left: 0; } }

@media screen and (max-width: 767px) {
  .p-process_list__item .box {
    font-size: 1.8rem; } }

@media screen and (max-width: 575px) {
  .p-process_list__item .box {
    padding: 1em .5em; } }

.p-process_notes {
  margin-top: 4rem;
  padding: 4rem;
  background: #fef3f3;
  border: solid 1px #ecaaa9; }
  .p-process_notes__list li {
    position: relative;
    padding-left: 1em; }
    .p-process_notes__list li:before {
      position: absolute;
      content: "・";
      left: 0;
      top: 0; }
  .p-process_notes__text dt {
    line-height: 1.875; }
    .p-process_notes__text dt:before {
      content: "＜"; }
    .p-process_notes__text dt:after {
      content: "＞"; }
  .p-process_notes__text dd:not(:last-child) {
    margin-bottom: 1em; }
  @media screen and (max-width: 991px) {
    .p-process_notes {
      padding: 3rem;
      margin-top: 3rem; } }
  @media screen and (max-width: 991px) {
    .p-process_notes {
      padding: 3rem 2rem; } }

/*============================================

  APPLICATION

==============================================*/
.p-application_main {
  padding: 10rem 0; }
  @media screen and (max-width: 991px) {
    .p-application_main {
      padding: 8rem 0; } }
  .p-application_main__cover {
    display: flex;
    justify-content: center; }
  .p-application_main__link:nth-child(n+2) {
    margin-left: 1em; }
  @media screen and (max-width: 767px) {
    .p-application_main__link {
      line-height: 1.34; } }

.p-application_register {
  width: 80%;
  margin: auto; }
  .p-application_register:nth-child(n+2) {
    margin-top: 10rem; }
  .p-application_register__tbl tr:nth-child(n+2) {
    border-top: solid 1px #ccc; }
  .p-application_register__tbl th, .p-application_register__tbl td {
    padding: 1.25em 1em; }
  .p-application_register__tbl th {
    width: 25%;
    text-align: left; }
  .p-application_register__tbl td {
    width: 75%; }
  .p-application_register__link {
    color: #dc252b;
    text-decoration: underline; }
    .p-application_register__link:hover {
      color: #264995; }
  .p-application_register__list {
    margin-top: 1em; }
    .p-application_register__list li {
      text-indent: -1em;
      margin-left: 1em; }
  @media screen and (max-width: 991px) {
    .p-application_register {
      width: 100%; }
      .p-application_register:nth-child(n+2) {
        margin-top: 8rem; } }
  @media screen and (max-width: 575px) {
    .p-application_register__tbl tr {
      display: block;
      padding: 1.5em 0; }
    .p-application_register__tbl th,
    .p-application_register__tbl td {
      display: block;
      width: 100%;
      padding: 0; }
    .p-application_register__tbl th {
      margin-bottom: 1em; } }

/*============================================

  OTHER PAGE

==============================================*/
/*--------------------------------------------
  COMPANY
--------------------------------------------*/
.p-company_information {
  padding: 10rem 0; }
  @media screen and (max-width: 991px) {
    .p-company_information {
      padding: 8rem 0; } }

.p-company_access {
  padding: 15rem 0;
  background: #fafafa; }
  @media screen and (max-width: 991px) {
    .p-company_access {
      padding: 8rem 0; } }

/*--------------------------------------------
  COMPLIANT
--------------------------------------------*/
.p-complaint_main {
  padding: 10rem 0; }
  .p-complaint_main__content {
    line-height: 2.25; }
    .p-complaint_main__content:nth-child(n+2) {
      margin-top: 8rem;
      padding-top: 8rem;
      border-top: solid 1px #f1f1f1; }
    .p-complaint_main__content .c-multicol__title {
      margin-bottom: 0; }
    .p-complaint_main__content h3 {
      font-size: 1.5em;
      margin-bottom: 1em; }
      .p-complaint_main__content h3:not(:first-child) {
        margin-top: 2em; }
    .p-complaint_main__content .c-dot_list li {
      line-height: 2.25; }
      .p-complaint_main__content .c-dot_list li:before {
        top: 1.1125em; }
      .p-complaint_main__content .c-dot_list li:nth-child(n+2) {
        margin-top: 0; }
    .p-complaint_main__content figure img {
      max-width: 100%;
      height: auto; }
    .p-complaint_main__content ol {
      line-height: 2.25;
      counter-reset: list_count01 0; }
      .p-complaint_main__content ol > li {
        position: relative;
        padding-left: 1.8em;
        counter-increment: list_count01 1; }
        .p-complaint_main__content ol > li:before {
          position: absolute;
          content: counter(list_count01) ")";
          top: 0;
          left: 0;
          width: 1.5em; }
        .p-complaint_main__content ol > li:nth-child(n+2) {
          margin-top: 3em; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .p-complaint_main__content .c-multicol__title {
      width: 30%; }
    .p-complaint_main__content .c-multicol__content {
      width: 70%; } }
  @media screen and (max-width: 991px) {
    .p-complaint_main {
      padding: 8rem 0; }
      .p-complaint_main__content:nth-child(n+2) {
        margin-top: 4rem;
        padding-top: 4rem; }
      .p-complaint_main__content .c-multicol__title {
        margin-bottom: 1em; }
      .p-complaint_main__content h3 {
        font-size: 1.25em;
        margin-bottom: .75em; }
      .p-complaint_main__content p {
        line-height: 1.6; }
        .p-complaint_main__content p:not(:last-child) {
          margin-bottom: .5em; }
      .p-complaint_main__content .c-dot_list li {
        line-height: 1.6; }
        .p-complaint_main__content .c-dot_list li:before {
          top: .8em; }
        .p-complaint_main__content .c-dot_list li:nth-child(n+2) {
          margin-top: .5em; }
      .p-complaint_main__content ol li {
        line-height: 1.6; }
        .p-complaint_main__content ol li:nth-child(n+2) {
          margin-top: 2em; } }

/*--------------------------------------------
  APPLICABLE
--------------------------------------------*/
.p-standards_main {
  padding: 10rem 0; }
  .p-standards_main__content {
    justify-content: space-between; }
    .p-standards_main__content:nth-child(n+2) {
      margin-top: 4rem;
      padding-top: 4rem;
      border-top: solid 1px #eee; }
    .p-standards_main__content .image {
      width: 35%; }
      .p-standards_main__content .image img {
        max-width: 100%;
        height: auto; }
    .p-standards_main__content .text {
      width: 60%; }
      .p-standards_main__content .text .c-ttl03 {
        margin-bottom: .5em; }
        .p-standards_main__content .text .c-ttl03 .ttl_ja {
          margin-bottom: 1em; }
      .p-standards_main__content .text ul {
        padding-left: 1em; }
  @media screen and (max-width: 991px) {
    .p-standards_main {
      padding: 8rem 0; }
      .p-standards_main__content .text ul {
        padding-left: 0; } }
  @media screen and (max-width: 575px) {
    .p-standards_main__content {
      display: block; }
      .p-standards_main__content .image {
        width: 100%;
        margin-bottom: 2rem; }
      .p-standards_main__content .text {
        width: 100%; } }

/*--------------------------------------------
  DECLARATION
--------------------------------------------*/
.p-declaration_main {
  padding: 10rem 0; }
  .p-declaration_main__text {
    text-align: center;
    font-size: 1.8rem; }
  .p-declaration_main__list {
    padding: 5px;
    margin: 4rem 0;
    background: -webkit-linear-gradient(45deg, #264995 0%, #a43185 50%, #dc252b 100%);
    background: -o-linear-gradient(45deg, #264995 0%, #a43185 50%, #dc252b 100%);
    background: linear-gradient(-45deg, #264995 0%, #a43185 50%, #dc252b 100%); }
    .p-declaration_main__list ul {
      background: #fff;
      padding: 6rem; }
      .p-declaration_main__list ul li {
        align-items: flex-end;
        flex-wrap: nowrap; }
        .p-declaration_main__list ul li:nth-child(n+2) {
          border-top: solid 1px #eee;
          padding-top: 3rem;
          margin-top: 3rem; }
        .p-declaration_main__list ul li .c-ttl03 {
          width: 30%;
          padding-right: 1em;
          margin-bottom: 0; }
          .p-declaration_main__list ul li .c-ttl03 .ttl_ja {
            margin-bottom: .5em; }
        .p-declaration_main__list ul li p {
          font-size: 1.8rem;
          width: 75%; }
  @media screen and (max-width: 1399px) {
    .p-declaration_main__text {
      font-size: 1.6rem; }
    .p-declaration_main__list ul {
      padding: 4rem; }
      .p-declaration_main__list ul li .c-ttl03 {
        font-size: 4.2rem; }
      .p-declaration_main__list ul li p {
        font-size: 1.6rem; } }
  @media screen and (max-width: 991px) {
    .p-declaration_main {
      padding: 8rem 0; }
      .p-declaration_main__list ul {
        padding: 3rem; }
        .p-declaration_main__list ul li {
          display: block; }
          .p-declaration_main__list ul li .c-ttl03 {
            width: 100%;
            padding: 0;
            margin-bottom: .25em; }
          .p-declaration_main__list ul li p {
            width: 100%; } }
  @media screen and (max-width: 767px) {
    .p-declaration_main__text {
      line-height: 2; }
    .p-declaration_main__list {
      padding: 3px; }
      .p-declaration_main__list ul {
        padding: 4rem 4%; }
        .p-declaration_main__list ul li .c-ttl03 {
          margin-bottom: .25em; }
        .p-declaration_main__list ul li p {
          width: 100%; } }
  @media screen and (max-width: 767px) {
    .p-declaration_main__list {
      margin: 3rem 0; } }

/*--------------------------------------------
  PRIVACY POLICY
--------------------------------------------*/
.p-privacy_policy {
  padding: 10rem 0; }
  .p-privacy_policy__text {
    line-height: 2;
    margin-bottom: 6rem; }
  .p-privacy_policy__list dt {
    position: relative;
    font-size: 2rem;
    padding-left: 1.5em;
    margin-bottom: .75em; }
    .p-privacy_policy__list dt:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: #dc252b;
      transform: scale(0.375); }
  .p-privacy_policy__list dd {
    line-height: 2; }
    .p-privacy_policy__list dd:not(:last-child) {
      margin-bottom: 6rem; }
  @media screen and (max-width: 991px) {
    .p-privacy_policy {
      padding: 8rem 0; }
      .p-privacy_policy__text {
        margin-bottom: 4rem; }
      .p-privacy_policy__list dd:not(:last-child) {
        margin-bottom: 4rem; } }
  @media screen and (max-width: 767px) {
    .p-privacy_policy__list dt {
      font-size: 1.8rem; } }

/*--------------------------------------------
  NEWS
--------------------------------------------*/
.p-news_archive {
  padding: 10rem 0; }
  .p-news_archive__list {
    margin-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .p-news_archive {
      padding: 8rem 0; }
      .p-news_archive__list {
        margin-bottom: 4rem; } }

.p-news_single {
  padding: 10rem 0; }
  .p-news_single__head {
    position: relative;
    font-size: 3.2rem;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: solid 1px #202020; }
    .p-news_single__head time {
      display: block;
      font-size: .5em;
      color: #bababa;
      font-family: "EB Garamond", "游明朝", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "MS P明朝", "MS 明朝", serif;
      line-height: 1;
      margin-bottom: 1em; }
  @media screen and (max-width: 991px) {
    .p-news_single {
      padding: 8rem 0; }
      .p-news_single__head {
        font-size: 2.4rem; } }

/*--------------------------------------------
  CONTACT
--------------------------------------------*/
.p-contact_main {
  padding: 10rem 0; }
  @media screen and (max-width: 991px) {
    .p-contact_main {
      padding: 6rem 0; } }

/*--------------------------------------------
  NOT FOUND
--------------------------------------------*/
.p-notfound_main {
  padding: 10rem 0; }
  @media screen and (max-width: 991px) {
    .p-notfound_main {
      padding: 6rem 0; } }
  .p-notfound_main h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
    text-align: center; }
  .p-notfound_main p {
    text-align: center;
    line-height: 2.5;
    margin-bottom: 2em; }
  @media screen and (max-width: 767px) {
    .p-notfound_main h2 {
      text-align: left; }
    .p-notfound_main p {
      text-align: left; } }

/*--------------------------------------------
  GUIDE
--------------------------------------------*/
.p-guide_main {
  padding: 10rem 0; }
  .p-guide_main__content {
    line-height: 2.25; }
    .p-guide_main__content:nth-child(n+2) {
      margin-top: 8rem;
      padding-top: 8rem;
      border-top: solid 1px #f1f1f1; }
    .p-guide_main__content h3 {
      font-size: 1.5em;
      margin-bottom: 1em; }
      .p-guide_main__content h3:not(:first-child) {
        margin-top: 2em; }
    .p-guide_main__content ol {
      line-height: 2.25;
      counter-reset: list_count01 0; }
      .p-guide_main__content ol > li {
        position: relative;
        padding-left: 1.8em;
        counter-increment: list_count01 1; }
        .p-guide_main__content ol > li:before {
          position: absolute;
          content: counter(list_count01) ")";
          top: 0;
          left: 0;
          width: 1.5em; }
        .p-guide_main__content ol > li:nth-child(n+2) {
          margin-top: 3em; }
  .p-guide_main__txtBox {
    margin-top: 1.875em;
    padding: 1.875em;
    background: #FAFAFA; }
    .p-guide_main__txtBox ul {
      margin-left: 1em; }
  .p-guide_main__group {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 3.125em; }
  .p-guide_main__box {
    width: 48%; }
    .p-guide_main__box.-blue {
      border: 1px solid #B0C1EE; }
      .p-guide_main__box.-blue .p-guide_main__head {
        background: #F3F3FE; }
    .p-guide_main__box.-red {
      border: 1px solid #ECAAA9; }
      .p-guide_main__box.-red .p-guide_main__head {
        background: #FEF3F3; }
  .p-guide_main__head {
    text-align: center;
    font-size: 1.5em;
    padding: 1.25em;
    line-height: 1; }
  .p-guide_main__body {
    font-size: 1em;
    padding: 1.875em; }
  .p-guide_main__item {
    margin-top: 3.125em; }
  .p-guide_main__catch {
    position: relative;
    font-size: 1.25em;
    line-height: 1;
    padding-left: .8em; }
    .p-guide_main__catch::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: .3em;
      height: .3em;
      background: #dc252b;
      border-radius: 50%; }
  .p-guide_main__txt {
    font-size: 1em;
    margin-top: 1.25em; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .p-guide_main__content .c-multicol__title {
      width: 30%; }
    .p-guide_main__content .c-multicol__content {
      width: 70%; } }
  @media screen and (max-width: 991px) {
    .p-guide_main {
      padding: 8rem 0; }
      .p-guide_main__content:nth-child(n+2) {
        margin-top: 4rem;
        padding-top: 4rem; }
      .p-guide_main__content .c-multicol__title {
        margin-bottom: 1em; }
      .p-guide_main__content h3 {
        font-size: 1.25em;
        margin-bottom: .75em; }
      .p-guide_main__content p {
        line-height: 1.6; }
        .p-guide_main__content p:not(:last-child) {
          margin-bottom: .5em; }
      .p-guide_main__content ol li {
        line-height: 1.6; }
        .p-guide_main__content ol li:nth-child(n+2) {
          margin-top: 2em; }
      .p-guide_main__txtBox {
        margin-top: 1.5em;
        padding: 1.5em; }
      .p-guide_main__group {
        margin-top: 2.5em; }
      .p-guide_main__head {
        font-size: 1.2em;
        padding: 1.2em; }
      .p-guide_main__body {
        font-size: 1em;
        padding: 1.5em; }
      .p-guide_main__item {
        margin-top: 2.5em; }
      .p-guide_main__catch {
        font-size: 1em; }
      .p-guide_main__txt {
        font-size: 1em;
        margin-top: 1em; } }
  @media screen and (max-width: 575px) {
    .p-guide_main__group {
      flex-direction: column; }
    .p-guide_main__box {
      width: 100%; }
      .p-guide_main__box:nth-child(n+2) {
        margin-top: 2em; } }

/*--------------------------------------------
  print
--------------------------------------------*/
