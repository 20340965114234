@charset "utf-8";
/*--------------------------------------------
  INDEX
--------------------------------------------*/
.p-idx_key{
  $amp : &;
  position: relative;
  &__inner{
    position: relative;
  }
  .key_img{
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top:calc(100vh - 6.25em);
  }
  .key_txt{
    max-width: $base_width;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    line-height: 1;
    z-index: 2;
    p{
      position: relative;
      width: 100%;
      text-align: center;
      margin:0 auto;
      &.txt_01{
        width: 74.195rem;
        height: 11.94rem;
      }
      &.txt_02{
        width: 73.427rem;
        height: 8.7rem;
      }
      &.txt_03{
        width: 103.739rem;
        height: 8.688rem;
        margin-top:3rem;
      }
      svg{
        width: 100%;
        height: 100%;
        fill: $font_color;
        fill-opacity:0;
        stroke: $font_color;
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }
    }
  }
  @include mq(screen){
    &.is-anime{
      .key_txt{
        p{
          svg{
            animation:draw_path01 1.4s ease-in forwards;
          }
          &.txt_02{
            svg{
              animation:draw_path02 1.4s ease-in forwards;
            }
          }
        }
      }
    }
  }
  @include mq(w1400){
    .key_txt{
      transform:translate(-50%,-50%) scale(.95);
    }
  }
  @include mq(sm){
    .key_img{
      padding-top: 75vw;
    }
    .key_txt{
      transform:translate(-50%,-50%) scale(.65);
    }
  }
  @include mq(xs){
    .key_img{
      padding-top: 75vw;
    }
    .key_txt{
      transform:translate(-50%,-50%) scale(.45);
    }
  }
  @include mq(ss){
    .key_img{
      padding-top: 75vw;
    }
    .key_txt{
      transform:translate(-50%,-50%) scale(.35);
    }
  }
}

.p-idx_advantage{
  $amp: &;
  padding:$margin_ll 0;
  &__list{
    margin-bottom: 5rem;
    li{
      width: 50%;
      figure{
        position: relative;
        .image{
          padding-top: 42rem;
          img{
            z-index: 1;
          }
          &:before{
            position: absolute;
            content:"";
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,1) 100%);
            z-index: 2;
          }
        }
        .title{
          position: absolute;
          left: 0;
          bottom:0;
          z-index: 2;
          color:#fff;
          padding:3rem;
        }
      }
    }
    p{
      line-height: 2.25;
      padding: 1.875em 3rem;
    }
  }
  @include mq(screen){
    &__title{
      transition:$transition_val;
      @include animation('fadeUp');
    }
    &__list{
      transition:$transition_val .3s;
      @include animation('fadeUp');
    }
    &__button{
      transition:$transition_val .9s;
      @include animation('fadeUp');
    }
    &.is-anime{
      #{$amp}__title,
      #{$amp}__list,
      #{$amp}__button{
        @include animation('fadeUp','after');
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
        margin-bottom: $margin_ss;
      li{
        figure{
          .image{
            padding-top: 100%;
          }
          .title{
            padding:2rem;
          }
        }
      }
      p{
        font-size: 1.5rem;
        line-height: 1.8;
        padding: 1em 2rem;
      }
    }
  }
  @include mq(xs){
    &__list{
      display: block;
      li{
        width: 100%;
        &:nth-child(n+2){
          margin-top: $margin_ss;
        }
        figure{
          .image{
            padding-top: 60%;
          }
        }
      }
    }
  }
  @include mq(ss){
    &__list{
      margin-bottom: 2rem;
    }
  }
}

.p-idx_cs{
  $amp : &;
  background: url('../img/index/idx_cs_bg.jpg') no-repeat center center / cover;
  padding: $margin_ll 0;
  &__list{
    justify-content: center;
    align-items: stretch;
    li{
      width: 22.5%;
      &:nth-child(n+2){
        margin-left:3.333333%;
      }
      a{
        display: block;
        color:#fff;
        .image{
          background: #fff;
          padding-top: 74%;
        }
        .text{
          border-bottom: solid 1px #fff;
          padding: 1.625em 0;
          line-height: 1;
          justify-content: space-between;
          align-items: center;
          h3{
            font-weight: 400;
            span{
              display: block;
              &.ttl_en{
                font-size: 1.5em;
                font-family: $base_font_en;
              }
              &.ttl_ja{
                margin-top: .875em;
              }
            }
          }
          .arrow{
            position: relative;
            width: 4.5rem;
            height: 4.5rem;
            display: inline-block;
            border:solid 1px #fff;
            transition:.6s;
            &:before{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%) scale(.75);
              @include icon('chevron-right');
            }
          }
        }
        @include mq(sm-){
          &:hover{
            opacity: .5;
            .text{
              .arrow{
                background: #fff;
                color:$font_color;
              }
            }
          }
        }
      }
    }
  }
  @include mq(screen){
    &__title{
      transition:$transition_val;
      @include animation('fadeUp');
    }
    &__list{
      transition:$transition_val .3s;
      @include animation('fadeUp');
    }
    &.is-anime{
      #{$amp}__title,
      #{$amp}__list{
        @include animation('fadeUp','after');
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
      justify-content: space-between;
      li{
        width: 48%;
        &:nth-child(n+2){
          margin-left:0;
        }
        &:nth-child(n+3){
          margin-top: 4%;
        }
        a{
          display: block;
          color:#fff;
          .image{
            padding-top: 60%;
            background: none;
          }
          .text{
            padding: 1.5em 0;
            .arrow{
              width: 4rem;
              height: 4rem;
            }
          }
        }
      }
    }
  }
}

.p-idx_regard{
  $amp : &;
  padding-top: $margin_ll;
  overflow: hidden;
  &__slide{
    position: relative;
    padding-top: 8rem;
  }
  &__img{
    position: absolute;
    right:0;
    top:0;
    width: 66.666666%;
    z-index: 2;
    background: #fff;
    .img_slide {
      .slide_in{
        padding-top: 62.5%;
      }
    }
  }
  &__text{
    position: relative;
    z-index: 1;
    width: 66.666666%;
    > *{
      position: relative;
      z-index: 2;
    }
    &:before{
      position: absolute;
      right:0;
      top:0;
      content:"";
      background: #f1f1f1;
      width: 100vw;
      height: 100%;
    }
    .text_slide{
      .slide_in{
        padding: 8rem calc(50% + 8rem) 8rem 0;
        >*{
          position: relative;
          &:before{
            position: absolute;
            content:"";
            right:0;
            top:0;
            width: 100%;
            height: 100%;
            background: $accent_color;
            z-index: 1;
            transition:all .4s .6s;
          }
        }
        p{
          margin-bottom: 5rem;
        }
        .link_btn{
          text-align: left;
          a{
            text-align: center;
            min-width: 1px;
            width: 100%;
          }
        }
      }
      &.slick-current{
        .slide_in{
          >*{
            &:before{
              width: 0;
            }
          }
        }
      }
    }
  }
  &__control{
    position: absolute;
    right:0;
    bottom:0;
    align-items: center;
    line-height: 1;
    .counter{
      font-family: $base_font_en;
      margin-right:1.875em;
      .total{
        padding-left: .5em;
        color:#bababa;
        &:before{
          content: "/";
          margin-right:.5em;
        }
      }
    }
    .controller{
      .arrow{
        position: relative;
        width: 4.5rem;
        height:4.5rem;
        color:$main_color;
        border:solid 1px $main_color;
        display: block;
        cursor:pointer;
        transition: $transition_val;
        &:before{
          position: absolute;
          left: 50%;
          top: 50%;
          transform:translate(-50%,-50%) scale(.75);
        }
        &.prev{
          margin-right:1rem;
          &:before{
            @include icon('chevron-left');
          }
        }
        &.next{
          &:before{
            @include icon('chevron-right');
          }
        }
        @include mq(sm-){
          &:hover{
            background: $main_color;
            color:#fff;
          }
        }
      }
    }
  }
  @include mq(screen){
    &__title{
      transition:$transition_val;
      @include animation('fadeUp');
    }
    &__slide{
      transition:$transition_val .3s;
      @include animation('fadeUp');
    }
    &__control{
      transition:$transition_val .9s;
      @include animation('fadeIn');
    }
    &.is-anime{
      #{$amp}__title,
      #{$amp}__slide{
        @include animation('fadeUp','after');
      }
      #{$amp}__control{
        @include animation('fadeIn','after');
      }
    }
  }
  @include mq(w1400){
    &__text{
      .text_slide{
        .slide_in{
          padding:5rem calc(50% + 4rem) 5rem 0;
          p{
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
  @include mq(sm){
    padding-top: $margin_md;
    &__slide{
      position: relative;
      padding-top: 0;
    }
    &__img{
      position: relative;
      padding-bottom: 8rem;
      right:0;
      top:0;
      width: 100%;
      z-index: 2;
      background: #fff;
      .img_slide {
        position:relative;
        z-index: 2;
        .slide_in{
          width: 70vw;
          padding-top: 42.5%;
        }
      }
      &:before{
        position: absolute;
        content:"";
        right:0;
        bottom:0;
        width: 70vw;
        height: calc(100% - 8rem);
        background: #f1f1f1;
      }
    }
    &__text{
      position: relative;
      z-index: 1;
      width: 100%;
      &:before{
        display: none;
      }
      .text_slide{
        .slide_in{
          padding: 4rem 0 0;
          p{
            margin-bottom: 3rem;
          }
          .link_btn{
            text-align: center;
            a{
              min-width: 300px;
              width: auto;
            }
          }
        }
      }
    }
    &__control{
      bottom:initial;
      top:2rem;
      z-index: 3;
      .counter{
        margin-right:1em;
      }
      .controller{
        .arrow{
          width: 4rem;
          height:4rem;
        }
      }
    }
  }
  @include mq(xs){
    &__img{
      position: static;
      padding-bottom: 0;
      width: 100%;
      margin-bottom: 3rem;
      .img_slide {
        position:relative;
        z-index: 2;
        .slide_in{
          width: 100%;
          padding-top: 50%;
        }
      }
      &:before{
        display: none;
      }
    }
    &__text{
      .text_slide{
        .slide_in{
          padding: 0;
          .link_btn{
            a{
              min-width: 50%;
            }
          }
        }
      }
      &.slick-dotted.slick-slider{
        margin-bottom: 0;
      }
    }
    &__control{
      margin-top:1em;
      position:static;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      .counter{
        display: none;
      }
      .controller{
        .slick-dots{
          position: static;
          margin-bottom: 1em;
          li{
            width: .7em;
            height: .7em;
            margin:0 .5em;
            button{
              padding: 0;
              width: 100%;
              height: 100%;
              background: $font_color;
              border-radius: 50%;
              opacity: .5;
              &:before{
                display: none;
              }
            }
            &.slick-active{
              button{
                opacity: 1;
              }
            }
          }
        }
      }
    }

  }
}

.p-idx_news{
  $amp: &;
  padding: $margin_ll 0;
  &__button{
    margin-top: 5rem;
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__button{
      margin-top: $margin_ss;
    }
  }
  @include mq(xs){
    padding: $margin_sm 0;
  }
  @include mq(screen){
    &__title{
      transition:$transition_val;
      @include animation('fadeUp');
    }
    &__list{
      transition:$transition_val .3s;
      @include animation('fadeUp');
    }
    &__button{
      transition:$transition_val .9s;
      @include animation('fadeUp');
    }
    &.is-anime{
      #{$amp}__title,
      #{$amp}__list,
      #{$amp}__button{
        @include animation('fadeUp','after');
      }
    }
  }
}