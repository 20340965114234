@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  $amp :&;
  position: absolute;
  left: 0;
  top:0;
  z-index: 120;
  background: #fff;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__logo{
    position: relative;
    z-index: 111;
    padding-left: 3rem;
    a{
      display: block;
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__toggle{
    position: fixed;
    z-index: 110;
    left: 0;
    top: 0;
    width: 10rem;
    height: 100vh;
    background: #fff;
    transition:$transition_val;
    .so_toggle{
      position: absolute;
      background: transparent;
      width: 3.6rem;
      height: 3.6rem;
      padding:0;
      left:50%;
      top:50%;
      transform:translate(-50%,-50%);
      .hamburger-icon{
        background: transparent;
        top:0;
        &:before,&:after{
          background: $font_color;
          top:0;
        }
        &:before{
          transform:translateY(-4px);
        }
        &:after{
          transform:translateY(4px);
        }
      }
    }
    .totop{
      position: absolute;
      left: 50%;
      bottom:3rem;
      line-height: 1;
      writing-mode: vertical-rl;
      padding-bottom: 7rem;
      transform:translateX(-50%);
      i{
        position: absolute;
        right: 50%;
        bottom:0;
        width: 1px;
        height: 5rem;
        background: $font_color;
        &:before{
          position: absolute;
          content:"";
          left: -2px;
          top:1rem;
          width:5px;
          height: 5px;
          border-radius: 50%;
          background: $font_color;
          transition: $transition_val;
        }
      }
      &:hover{
        opacity: $opacity_val;
        i{
          &:before{
            top:calc(100% - 1rem);
          }
        }
      }
    }
  }
  .slideout-open & {
    &__logo{
      img{
        visibility: hidden;
      }
    }
    &__toggle{
      background: rgba(255,255,255,0);
      .so_toggle{
        .hamburger-icon{
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
      .totop{
        display: none;
      }
    }
  }
  @include mq(w1400){
    padding: 2rem;
    position: static;
    &__logo{
      position: static;
      padding-left: 1;
      padding-left: 0;
      a{
        img{
          height: 4.5rem;
        }
      }
    }
    &__toggle{
      position: relative;
      width: 5rem;
      height: 5rem;
      background: transparent;
      .so_toggle{
        position: absolute;
        width: 72%;
        height: 72%;
      }
    }
  }
  @include mq(sm){
    // padding: 2rem;
    // position: static;
    // &__logo{
    //   position: static;
    //   padding-left: 1;
    //   padding-left: 0;
    //   a{
    //     img{
    //       height: 4.5rem;
    //     }
    //   }
    // }
    // &__toggle{
    //   position: relative;
    //   width: 5rem;
    //   height: 5rem;
    //   background: transparent;
    //   .so_toggle{
    //     position: absolute;
    //     width: 72%;
    //     height: 72%;
    //   }
    // }
  }
}