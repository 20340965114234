@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?weatbq');
  src:  url('../fonts/icomoon.eot?weatbq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?weatbq') format('truetype'),
    url('../fonts/icomoon.woff?weatbq') format('woff'),
    url('../fonts/icomoon.svg?weatbq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @if $type == 'mail' {
    content: "\e900";
  }
  @else if $type == 'chevron-left' {
    content: "\f053";
  }
  @else if $type == 'chevron-right' {
    content: "\f054";
  }
  @else if $type == 'chevron-up' {
    content: "\f077";
  }
  @else if $type == 'chevron-down' {
    content: "\f078";
  }
  @else if $type == 'angle-left2' {
    content: "\f100";
  }
  @else if $type == 'angle-right2' {
    content: "\f101";
  }
  @else if $type == 'angle-up2' {
    content: "\f102";
  }
  @else if $type == 'angle-down2' {
    content: "\f103";
  }
  @else if $type == 'angle-left' {
    content: "\f104";
  }
  @else if $type == 'angle-right' {
    content: "\f105";
  }
  @else if $type == 'angle-up' {
    content: "\f106";
  }
  @else if $type == 'angle-down' {
    content: "\f107";
  }
}
