@charset "utf-8";

/*--------------------------------------------
  GUIDE
--------------------------------------------*/
.p-guide_main{
  $amp: &;
  padding: $margin_lg 0;
  &__content{
    line-height: 2.25;
    &:nth-child(n+2){
      margin-top: $margin_md;
      padding-top: $margin_md;
      border-top: solid 1px #f1f1f1;
    }
    h3{
      font-size: 1.5em;
      margin-bottom: 1em;
      &:not(:first-child){
        margin-top: 2em;
      }
    }
    ol{
      line-height: 2.25;
      counter-reset:list_count01 0;
      > li{
        position: relative;
        padding-left: 1.8em;
        counter-increment: list_count01 1;
        &:before{
          position: absolute;
          content:counter(list_count01)')';
          top: 0;
          left: 0;
          width: 1.5em;
        }
        &:nth-child(n+2){
          margin-top: 3em;
        }
      }
    }
  }
  &__txtBox{
    margin-top: 1.875em;
    padding: 1.875em;
    background: #FAFAFA;
    ul{
      margin-left: 1em;
    }
  }
  &__group{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 3.125em;
  }
  &__box{
    width: 48%;
    &.-blue{
      border: 1px solid #B0C1EE;
      #{$amp}__head{
        background: #F3F3FE;
      }
    }
    &.-red{
      border: 1px solid #ECAAA9;
      #{$amp}__head{
        background: #FEF3F3;
      }
    }
  }
  &__head{
    text-align: center;
    font-size: 1.5em;
    padding: 1.25em;
    line-height: 1;
  }
  &__body{
    font-size: 1em;
    padding: 1.875em;
  }
  &__item{
    margin-top: 3.125em;
  }
  &__catch{
    position: relative;
    font-size: 1.25em;
    line-height: 1;
    padding-left: .8em;
    &::before{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: .3em;
      height: .3em;
      background: $main_color;
      border-radius: 50%;
    }
  }
  &__txt{
    font-size: 1em;
    margin-top: 1.25em;
  }
  @include mq(sm-md){
    &__content{
      .c-multicol__title{
        width: 30%;
      }
      .c-multicol__content{
        width: 70%;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__content{
      &:nth-child(n+2){
        margin-top: $margin_ss;
        padding-top: $margin_ss;
      }
      .c-multicol__title{
        margin-bottom: 1em;
      }
      h3{
        font-size: 1.25em;
        margin-bottom: .75em;
      }
      p{
        line-height: 1.6;
        &:not(:last-child){
          margin-bottom: .5em;
        }
      }
      ol{
        li{
          line-height: 1.6;
          &:nth-child(n+2){
            margin-top: 2em;
          }
        }
      }
    }
      
    &__txtBox{
      margin-top: 1.5em;
      padding: 1.5em;
    }
    &__group{
      margin-top: 2.5em;
    }
    &__head{
      font-size: 1.2em;
      padding: 1.2em;
    }
    &__body{
      font-size: 1em;
      padding: 1.5em;
    }
    &__item{
      margin-top: 2.5em;
    }
    &__catch{
      font-size: 1em;
    }
    &__txt{
      font-size: 1em;
      margin-top: 1em;
    }
  }
  @include mq(ss){
    &__group{
      flex-direction: column;
    }
    &__box{
      width: 100%;
      &:nth-child(n+2){
        margin-top: 2em;
      }
    }
  }
}