html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}
body {
  line-height:1;
}
article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display:block;
}
nav ul {
  list-style:none;
}
blockquote, q {
  quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}
a {
  margin:0;
  padding:0;
  border:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}
img {
  vertical-align: top;
}
mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}
del {
  text-decoration: line-through;
}
abbr[title], dfn[title] {
  border-bottom:1px dotted #000;
  cursor:help;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}
input, select {
  vertical-align:middle;
}
ul li,
ol li {
  list-style-type: none;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}