@charset "utf-8";
/*-----------------------------------------------------
  Wordpress EDITOR
------------------------------------------------------*/
.wp-editor{
  line-height: 1.875;
  &:not(:last-child){
    margin-bottom: $margin_sm;
  }
  *{
    + p,
    + ul,
    + ol,
    + .c-rwdsheet{
      margin-top: 1em;
    }
    + h2,
    + h3,
    + h4{
      margin-top: 2em;
    }
  }
  h2{
    font-size: 1.75em;
    line-height: 1.3;
  }
  h3{
    font-size: 1.5em;
    line-height: 1.3;
  }
  h4{
    font-size: 1.25em;
    line-height: 1.3;
  }
  strong{
    font-weight: 700;
  }
  em{
    font-style: italic;
  }
  blockquote{
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  img{
    display: block;
    &.alignleft{
      margin: 0 auto 0 0;
    }
    &.aligncenter{
      margin: 0 auto;
    }
    &.alignright{
      margin: 0 0 0 auto;
    }
    &[class*="attachment-"] ,
    &[class*="wp-image-"]{
      height: auto;
      max-width: 100%;
    }
  }
  hr{
    border-top-color: #bababa;
    margin:2em 0;
  }
  a{
    color: $accent_color;
    text-decoration: underline;
    word-break: break-all;
    @include mq(sm-){
      &:hover,
      &:focus{
        opacity: .7;
        text-decoration:none;
      }
    }
  }
  ul,
  ol{
    line-height: 1.6;
    >li{
      position: relative;
      padding-left: 1.5em;
      &:not(:first-child){
        margin-top: .6em;
      }
      &:before{
        position: absolute;
        content:"";
        left: 0;
      }
    }
  }
  ul{
    > li {
      &:before{
        top: .3em;
        width: 1em;
        height: 1em;
        background: $main_color;
        border-radius: 50%;
        transform:scale(.375);
      }
    }
  }
  ol{
    counter-reset: list_count01 0;
    > li {
      padding-left: 1.5em;
      counter-increment: list_count01 1;
      &:before{
        top: 0;
        content:counter(list_count01)'.';
        width: 1.5em;
        padding-right: .25em;
        text-align: right;
        color:$main_color;
      }
    }
  }
  table{
    width: 100%;
    th,
    td{
      padding: 1em;
      border: 1px solid #ddd;
    }
    th{
      background-color: #bababa;
    }
  }
  @include mq(sm){
    &:not(:last-child){
      margin-bottom:$margin_ss;
    }
    *{
      + p,
      + ul,
      + ol,
      + .c-rwdsheet{
        margin-top: 1.5em;
      }
      + h2,
      + h3,
      + h4{
        margin-top: 2.5em;
      }
    }
    h2{
      font-size: 1.5em;
    }
    h3{
      font-size: 1.35em;
    }
    h4{
      font-size: 1.2em;
    }
  }
}

/*-----------------------------------------------------
  Wordpress Pagination
------------------------------------------------------*/
.wp-pagenavi{
  display: flex;
  justify-content: center;
  align-items: center;
  >a,
  >span{
    display: block;
    width: 2em;
    line-height: 2;
    text-align: center;
  }
  .current{
    background: #202020;
    color:#fff;
    text-decoration: underline;
  }
  a{
    background: $main_color;
    color:#fff;
    &:hover{
      background: $accent_color;
    }
  }
  >*{
    &:nth-child(n+2){
      margin-left: .5em;
    }
  }
}