@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 92%;
  max-width: $base_width;
  margin-left: auto;
  margin-right: auto;

  &.-full {
    width: 100%;
  }
}
.c-multicol{
  @include mq(sm-){
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__title{
      width: 33%;
    }
    &__content{
      width: 66%;
    }
  }
}

.c-under_key{
  position: relative;
  &__inner{
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    z-index: 2;
    transform:translateX(-50%);
  }
  .key_img{
    position: relative;
    width: 100%;
    padding-top: 27.5rem;
    z-index: 1;
  }
  .key_txt{
    position: absolute;
    font-size: 8rem;
    line-height: 1;
    color: #fff;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    .ttl_en{
      font-family: $base_font_en;
      padding-bottom: .25em;
    }
    .ttl_ja{
      font-size: .2em;
      margin-left: 1.25em;
      font-weight: 400;
    }
  }
  @include mq(sm){
    .key_img{
      padding-top: 25rem;
    }
    .key_txt{
      font-size: 6rem;
      .ttl_ja{
        font-size: .25em;
      }
    }
  }
  @include mq(xs){
    .key_img{
      padding-top: 20rem;
    }
    .key_txt{
      display: block;
      font-size: 5rem;
      .ttl_ja{
        margin-top: .25em;
        margin-left: 0;
        font-size: .4em;
      }
    }
  }
}
/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-lead_box{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
  margin-bottom: $margin_sm;
  .ttl_ja{
    position: relative;
    font-weight: 400;
    padding-left:1.5em;
    margin-right: 4em;
    white-space:nowrap;
    &:before{
      position: absolute;
      content:"";
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: $main_color;
      transform:scale(.375);
    }
  }
  .ttl_en{
    font-family:$base_font_en;
    font-size: 5em;
    line-height: 1;
    margin-bottom: .4em;
    &.-mgb_sm{
      margin-bottom:0;
    }
    span{
      display: inline-block;
      color: $main_color;
      background: -webkit-linear-gradient(0deg, $accent_color, $sub_color, $main_color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: .25em;
    }
  }
  p{
    line-height: 2.25;
  }
  &.-white{
    color:#fff;
    .ttl_ja{
      &:before{
        background: #fff;
      }
    }
    .ttl_en{
      span{
        background: none;
        color:#fff;
        -webkit-text-fill-color: #fff;
        background-clip: none;
      }
    }
  }
  @include mq(sm){
    display: block;
    margin-bottom: $margin_ss;
    .ttl_ja{
      margin-right: 0;
      margin-bottom: .75em;
    }
    .ttl_en{
      font-size: 4.2em;
      margin-bottom: .3em;
    }
    p{
      line-height: 1.8;
    }
  }
  @include mq(xs){
    .ttl_en{
      font-size: 3.6em;
    }
    p{
      line-height: 1.8;
    }
  }
}

.c-num_ttl{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom:$margin_ss - 1rem;
  .number{
    font-family: $base_font_en;
    font-size: 9rem;
    line-height: 1;
  }
  .ttl{
    font-size: 1.6rem;
    margin-left: 1em;
    padding-top: .75em;
    .en{
      font-size: 3em;
      font-family: $base_font_en;
      line-height: 1;
      padding-bottom: .325em;
    }
    h3{
      font-weight: 400;
      line-height: 1.4;
    }
  }
  @include mq(w1400){
    .number{
      font-size: 8rem;
    }
  }
  @include mq(xs){
    margin-bottom: 2rem;
    .number{
      font-size: 7rem;
    }
    .ttl{
      font-size: 1.4rem;
    }
  }
}

.c-ttl01{
  font-size: 4.8rem;
  line-height: 1;
  .ttl_en{
    margin-bottom: .5em;
    font-family: $base_font_en;
  }
  .ttl_ja{
    font-size: .33333em;
    font-weight: 400;
  }
  @include mq(sm){
    font-size: 3.6rem;
    .ttl_ja{
      font-size: .415em;
    }
  }
}

.c-ttl02{
  text-align: center;
  line-height: 1;
  font-size: 4.8rem;
  margin-bottom: 1em;
  .ttl_en{
    font-family:$base_font_en;
    line-height: 1;
    span{
      display: inline-block;
      color: $main_color;
      background: -webkit-linear-gradient(0deg, $accent_color, $sub_color, $main_color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .ttl_ja{
    font-size: .33333333em;
    font-weight: 400;
    margin-top: 1em;
  }
  @include mq(sm){
    font-size: 3.6rem;
    .ttl_ja{
      font-size: .415em;
    }
  }
}

.c-ttl03{
  line-height: 1;
  font-size: 4rem;
  margin-bottom: 1em;
  .ttl_en{
    font-family: $base_font_en;
  }
  .ttl_ja{
    position: relative;
    font-size: .417em;
    font-weight: 400;
    padding-left: 1.5em;
    margin-bottom: 1.5em;
    &:before{
      position: absolute;
      content:"";
      left: 0;
      top: 0;
      width: 1em;
      height: 1em;
      background: $main_color;
      border-radius: 50%;
      transform: scale(.375);
    }
  }
  @include mq(sm){
    margin-bottom: .5em;
    font-size: 3.6rem;
    .ttl_ja{
      font-size: .415em;
      margin-bottom: 1em;
    }
  }
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  display: flex;
  justify-content: center;
  align-items:center;
  margin-top: 4rem;
  text-align: center;
  &.-flex{
    .btn{
      width: calc((100% - 4rem) / 2 );
      a{
        display: block;
      }
      @include mq(xs-){
        &:nth-child(n+2){
          margin-left:4rem;
        }
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top:2rem;
        }
      }
    }
  }
  @include mq(xs){
    display: block;
    &.-flex{
      .btn{
        width:100%;
      }
    }
  }
}
.c-btn01{
  line-height: 1;
  text-align: center;
  a{
    position: relative;
    display: inline-block;
    padding: 1.375em 2em;
    min-width: 300px;
    background: -webkit-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
    background: -o-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
    background: linear-gradient(to right, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
    background-size:300% 100%;
    background-position: right center;
    color:#fff;
    font-family:$base_font_en;
    span{
      position: relative;
      display: block;
    }
    @include mq(sm-){
      &:hover{
        background-position: left center;
      }
    }
  }
  &.-blue{
    a{
      // position: relative;
      // display: inline-block;
      // padding: 1.375em 2em;
      // min-width: 300px;
      background: -webkit-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
      background: -o-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
      background: linear-gradient(to right, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
      background-size:300% 100%;
      background-position: right center;      
      @include mq(sm-){
        &:hover{
          background-position: left center;
        }
      }
    }
  }
  @include mq(sm){
    a{
      padding: 1.25em 2em;
    }
  }
  @include mq(ss){
    a{
      min-width: 50%;
    }
  }
}

.c-link01{
  line-height: 1;
  a{
    @include mq(sm-){
      &:hover{
      }
    }
  }
}


/*--------------------------------------------
  LIST
--------------------------------------------*/
.c-dot_list{
  li{
    position: relative;
    padding-left: 1em;
    line-height: 1.6;
    &:before{
      position: absolute;
      content:"";
      top: .8em;
      left: 0;
      width: .4em;
      height: 1px;
      background: $font_color;
    }
    &:nth-child(n+2){
      margin-top: .5em;
    }
  }
}
/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tblbox{
  .sheet01{
    tr{
      border-bottom:solid 1px #f1f1f1;
    }
    th,td{
      padding: 1.875em 0;
      text-align: left;
      vertical-align: top;
    }
    th{
      width: 20%;
      color: #bababa;
      line-height: 2;
      .cell_in{
        font-size: 1.125em;
      }
    }
    td{
      width: 80%;
      line-height: 2.25;
      .cell_in{
        padding-top: .125em;
      }
    }
    @include mq(sm){
      th{
        width: 20%;
        color: #bababa;
      }
    }
    @include mq(ss){
      tr{
        display: block;
        padding-bottom: 1.5em;
        &:nth-child(n+2){
          margin-top: 1.5em;
        }
      }
      th,
      td{
        display: block;
        padding: 0;
        width: 100%;
      }
      th{
        line-height: 1;
        .cell_in{
        }
      }
      td{
        line-height: 1.6;
        margin-top: .75em;
      }
    }
  }
}

.c-rwdsheet{
  @include mq(xs){
    &:after{
      display: block;
      content:"※横にスクロールできます";
      text-align: center;
    }
    &__inner{
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      &::-webkit-scrollbar {
        height: .6rem;
      }
      &::-webkit-scrollbar-track {
      background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: $main_color;
      }
    }
    table{
      width: auto !important;
      min-width: 100%;
      th,
      td{
        width: auto !important;
        white-space: nowrap;
      }
    }
  }
}
/*--------------------------------------------
  FORM
--------------------------------------------*/
.c-form_box{
  .sheet01{
    width: 80%;
    margin:0 auto;
    tr{
      &:nth-child(n+2){
        border-top:solid 1px #ccc;
      }
    }
    th,
    td{
      padding: 1.25em 1em;
    }
    th{
      width: 25%
    }
    td{
      width: 75%;
      .mwform-file-delete{
        display: none;
      }
    }
    .form_label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &.-required{
        &:after{
          content:"*";
          font-size: 1em;
          color: #c11;
          margin-left: .5em;
        }
      }
    }
    .form_item{
      .i_input{
        &.-short{
          width: 100%;
        }
        &.-middle{
          width: 100%;
        }
        &.-long{
          width: 100%;
        }
        .error{
          color: #c11;
          margin-top: .5em;
          font-size: .875em;
        }
      }
    }
  }
  .form_btn{
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    a,
    input[type="button"],
    input[type="submit"]{
      padding: 1.375em 2em;
      width: 280px;
      background: -webkit-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
      background: -o-linear-gradient(left, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
      background: linear-gradient(to right, $accent_color 0%, $accent_color 34%, $sub_color 50%, $main_color 66%,$main_color 100%);
      background-size:300% 100%;
      background-position: right center;
      border:none;
      outline: none;
      color:#fff;
      font-family:$base_font_en;
      cursor: pointer;
      transition:$transition_val;
      text-align: center;
      &.btn_back{
        background: #999;
        margin-left: 1em;
      }
      @include mq(sm-){
        &:hover{
          background-position: left center;
        }
        &.btn_back{
          background: #999;
          &:hover{
            opacity: 5;
          }
        }
      }
    }
    &.-blue{
      input[type="submit"]{
        background: -webkit-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
        background: -o-linear-gradient(left, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
        background: linear-gradient(to right, $main_color 0%, $main_color 34%, $sub_color 50%, $accent_color 66%,$accent_color 100%);
        background-size:300% 100%;
        background-position: right center;   
        &:hover{
          opacity: 1;
          background-position: left center;
        }
      }
    }
  }
  &.-thanks{
    h2{
      text-align: center;
      font-size: 1.75em;
      margin-bottom: 1.5em;
    }
    p{
      text-align: center;
      line-height: 2.5;
    }
  }
  @include mq(sm){
    .sheet01{
      width: 100%;
      th{
        width: 30%;
      }
      td{
        width: 70%;
      }
    }
  }
  @include mq(ss){
    .sheet01{
      width: 100%;
      tr{
        display: block;
        padding: 1.5em 0;
      }
      th,
      td{
        display: block;
        width: 100%;
        padding: 0;
      }
      th{
        margin-bottom: 1em;
      }
    }
    .form_btn{
      margin-top: 1.5em;
      input[type="button"],
      input[type="submit"]{
        display: block;
        margin: 0 auto;
        &.btn_back{
          margin-left: auto;
          margin-top: 1em;
        }
      }
    }
  }
}
/*--------------------------------------------
  PARTS - NEWS
--------------------------------------------*/
.c-news_list{
  li{
    margin-top: -1px;
    a{
      position: relative;
      padding: 3rem 5rem;
      align-items: stretch;
      line-height: 1;
      border-bottom:solid 1px #f1f1f1;
      border-top:solid 1px #f1f1f1;
      margin-top:-1px;
      &:before{
        position: absolute;
        left: initial;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        content:"";
        background: #f1f1f1;
        transition:.6s;
        z-index: 1;
      }
      > *{
        position: relative;
        z-index: 2;
      }
      time{
        font-family: $base_font_en;
        font-size: 1.8rem;
        padding: .3rem 0;
        color: #bababa;
        width: 7em;
      }
      .title{
        line-height: 1.5;
        width: calc(100% - 7em * 1.125);
      }
      @include mq(sm-){
        &:hover{
          &:before{
            width: 100%;
            left: 0;
            right: initial;
          }
        }
      }
    }
  }
  @include mq(xs){
    li{
      a{
        padding: 2rem 1.5rem;
        display: block;
        time{
          width: 100%;
          display: block;
        }
        .title{
          width: 100%;
          display: block;
        }
      }
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 62.5%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @include mq(xs){
    padding-top: 125%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-pe_none {
  pointer-events: none;
}

.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}
.c-mgt0{
    margin-top: 0 !important; 
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}
